import AppConstants from 'AppConstants'

/*
Google analytics
eventCategory	  text	    yes	  Typically the object that was interacted with (e.g. 'Video')
eventAction	    text	    yes	  The type of interaction (e.g. 'play')
eventLabel	    text	    no	  Useful for categorizing events (e.g. 'Fall Campaign')
eventValue	    integer	  no	  A numeric value associated with the event (e.g. 42)
*/
const Analytics = {
  send: (eventCategory, eventAction, props) => {
    if (process.env.GATSBY_ENV === AppConstants.DEVELOPMENT) {
      console.log('Analytics:', eventCategory, eventAction, props)
    } else {
      gtag('event', eventAction, {
        event_category: eventCategory,
        event_label: JSON.stringify(props)
      })
      fbq('track', `${eventCategory}_${eventAction}`, props)
    }
  },
  gEcom: (eventName, params) => {
    if (process.env.GATSBY_ENV === AppConstants.DEVELOPMENT) {
      console.log('GA eCommerce:', eventName, params)
    } else {
      gtag('event', eventName, params)
    }
  }
}

export default Analytics