import {EventEmitter2} from 'eventemitter2'
import assign from 'object-assign'
import WindowConstants from 'WindowConstants'
import WindowActions from 'WindowActions'
import AppConstants from 'AppConstants'
import Dispatcher from '../dispatcher'
import isRetina from 'is-retina'

const _windowSize = { w: typeof window !== 'undefined' ? window.innerWidth : 0, h: typeof window !== 'undefined' ? window.innerHeight : 0 }
const _mouse = { x: 0, y: 0, nX: 0, nY: 0 }
let _orientation = WindowConstants.LANDSCAPE
let _viewType, _viewMediaQuery = ''

const resize = () => {
  if (_windowSize.w === window.innerWidth && _windowSize.h === window.innerHeight) return
  WindowActions.resize(window.innerWidth, window.innerHeight)
}

const mousemove = (e) => {
  _mouse.x  = e.clientX || _mouse.x
  _mouse.y  = e.clientY || _mouse.y
  _mouse.nX = (_mouse.x / _windowSize.w) * 2 - 1
  _mouse.nY = (_mouse.y / _windowSize.h) * 2 + 1
}

const _isRetina = () => {
  return isRetina()
}

const _getDevicePixelRatio = () => {
  const scale = (window.devicePixelRatio === undefined) ? 1 : window.devicePixelRatio
  return (scale > 1) ? 2 : 1
}

const _getImageDeviceExtension = () => {
  const retina = _isRetina()
  let str = '@1x'
  if (retina) str = '@2x'
  return str
}

const _getViewType = () => {
  let type = AppConstants.DESKTOP
  if (Store.Size.w < AppConstants.MQ_MEDIUM) type = AppConstants.MOBILE
  else if (Store.Size.w < AppConstants.MQ_LARGE) type = AppConstants.TABLET
  return type
}

const _getViewMediaQuery = () => {
  let mq = ''
  if (Store.Size.w < AppConstants.MQ_XSMALL) mq = AppConstants.MQ_XSMALL
  else if (Store.Size.w < AppConstants.MQ_SMALL) mq = AppConstants.MQ_SMALL
  else if (Store.Size.w < AppConstants.MQ_MEDIUM) mq = AppConstants.MQ_MEDIUM
  else if (Store.Size.w < AppConstants.MQ_LARGE) mq = AppConstants.MQ_LARGE
  else if (Store.Size.w < AppConstants.MQ_XLARGE) mq = AppConstants.MQ_XLARGE
  else if (Store.Size.w < AppConstants.MQ_XXLARGE) mq = AppConstants.MQ_XXLARGE
  else mq = AppConstants.MQ_XXLARGE
  return mq
}

if (typeof window !== 'undefined') {
  window.addEventListener('resize', resize)
  window.addEventListener('mousemove', mousemove)
}

const Store = assign({}, EventEmitter2.prototype, {
  devicePixelRatio: () => _getDevicePixelRatio(),
  isRetina: () => _isRetina(),
  orientation: () => _orientation,
  getViewType: () => _viewType,
  getViewMediaQuery: () => _viewMediaQuery,
  Size: _windowSize,
  Mouse: _mouse,
  dispatchToken: Dispatcher.register((payload) => {
    const actionType = payload.type
    const item = payload.item
    switch (actionType) {
    case WindowConstants.WINDOW_RESIZE:
      _windowSize.w = item.windowW
      _windowSize.h = item.windowH
      _orientation = (_windowSize.w > _windowSize.h) ? WindowConstants.LANDSCAPE : WindowConstants.PORTRAIT
      _viewType = _getViewType()
      _viewMediaQuery = _getViewMediaQuery()
      Store.emit(actionType)
      break
    case WindowConstants.ENABLE_SCROLL:
      document.documentElement.classList.remove('is-fixed')
      Store.emit(actionType)
      break
      case WindowConstants.DISABLE_SCROLL:
      document.documentElement.classList.add('is-fixed')
      Store.emit(actionType)
      break
    default:
      Store.emit(actionType, item)
      break
    }
    return true
  })
})

export default Store
