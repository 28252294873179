import React, { Component } from 'react'
import autosize from 'autosize'
import styles from './index.module.scss'

let feedback = false

class TextArea extends Component {
  state = {
    value: this.props.value
  }
  constructor(props) {
    super(props)
    this.textarea = React.createRef()
  }
  componentDidMount() {
    autosize(this.textarea.current)
  }
  setCustomValidity(msg) {
    this.feedback.textContent = msg
    feedback = true
  }
  onChange = event => {
    this.setState({ value: event.target.value })
    if (feedback) {
      this.feedback.textContent = ''
      feedback = false
    }
    if (this.props.onChange) this.props.onChange(event.target.value)
  }
  onFocus = () => {
    if (this.props.onFocus) this.props.onFocus()
  }
  onBlur = () => {
    if (this.props.onBlur) this.props.onBlur()
  }
  setValueExplicit = (value) => {
    this.setState({ value })
  }
  render() {
    return (
      <div className={`${this.props.className} u-relative`}>
        <textarea
          ref={this.textarea}
          className={`${styles.textarea} ${this.props.negative ? `t-color-white ${styles.negative}` : `t-color-black`} t-paragraph-1 u-fit-w u-block u-pad-t--20 u-pad-b--20 form-control`}
          id={this.props.id}
          value={this.state.value}
          placeholder={this.props.placeholder}
          required={this.props.required}
          maxLength={this.props.maxLength ? this.props.maxLength : 524288}
          onChange={this.onChange}
          rows='1'
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <div className={`${styles.feedback} invalid-feedback t-paragraph-0`} ref={el => this.feedback = el}></div>
      </div>
    )
  }
}

TextArea.defaultProps = {
  className: '',
  value: '',
  required: false
}

export default TextArea
