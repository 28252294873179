import React, { Component } from 'react'
import ReactSelect from 'react-select'
import WindowStore from 'WindowStore'
import AppConstants from 'AppConstants'
import styles from './index.module.scss'

let feedback = false

class Select extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value === undefined ? this.props.options[0].value : this.props.value
    }
  }
  onChange = event => {
    this.setState({ value: event })
    if (feedback) {
      this.feedback.textContent = ''
      feedback = false
    }
  }
  onChangeMobile = event => {
    const value = this.props.options.filter(item => item.value === event.currentTarget.value)[0]
    this.setState({ value: value })
    if (feedback) {
      this.feedback.textContent = ''
      feedback = false
    }
  }
  setCustomValidity(msg) {
    this.feedback.textContent = msg
    feedback = true
  }
  getValue = () => {
    if (Array.isArray(this.state.value)) return this.state.value[0]
    else return this.state.value
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.value.value !== this.state.value.value) {
      if (this.props.onChange) this.props.onChange(this.state)
    }
  }
  render() {
    const viewType = WindowStore.getViewType()
    const useCustomSelect = viewType === AppConstants.DESKTOP ? true : false
    return (
      <div className={`${this.props.className} u-relative`}>
        { useCustomSelect &&
        <ReactSelect
          className={`${styles.reactSelectContainer} REACT_SELECT t-paragraph-2 line-height-normal`}
          classNamePrefix={`REACT_SELECT`}
          required={this.props.required}
          value={this.state.value}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          isSearchable={this.props.isSearchable}
          options={this.props.options}
        />
        }
        { !useCustomSelect &&
        <div>
          <select
            className={`${styles.select} u-fit-w t-paragraph-1 t-color-black u-block form-control`}
            placeholder={this.props.placeholder}
            required={this.props.required}
            value={this.state.value.value}
            onChange={this.onChangeMobile}
          >
            {
            this.props.options.map((option, index) => {
              const disabled = option.disabled ? true : false
              let dom = <option value={option.value} key={`${this.props.id}-option-${index}`}>{option.label}</option>
              if (disabled) dom = <option value={option.value} disabled={disabled} key={`${this.props.id}-option-${index}`}>{option.label}</option>
              return dom
            })
            }
          </select>
          <span className={`${styles.arrowIcon} u-absolute`}>
            <svg className="u-fit">
              <use xlinkHref="#arrow-icon-vertical" />
            </svg>
          </span>
        </div>
        }
        <div className={`${styles.feedback} invalid-feedback t-paragraph-0`} ref={el => this.feedback = el}></div>
      </div>
    )
  }
}

Select.defaultProps = {
  className: '',
  isSearchable: false,
  required: false
}

export default Select
