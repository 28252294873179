import React, { Component } from 'react'
import SimpleTextIconButton from 'Components/UI/SimpleTextIconButton'
import styles from './index.module.scss'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import transform from 'dom-css-transform'
import SearchLayer from 'Components/UI/SearchLayer'

class SearchPanel extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.searchInput = React.createRef()
    this.searchLayer = React.createRef()
    this.state = {
      isOpen: false
    }
  }
  render() {
    const openClass = this.state.isOpen ? styles.open : ''
    return (
      <div ref={this.parent} className={`${styles.parent} ${openClass} u-fixed u-fit u-z-index--9981 u-overflow-x-h`}>
        <div className={`${styles.container} u-relative u-fit-w u-pos-tl u-z-index--2`}>
          <div className='u-container-fluid u-text-center u-fit u-pad-x--lg'>
            <div className='u-flex u-col-lg-10 u-offset-lg-1 u-height--50'></div>
            <div className="u-row">
              <div className='u-flex u-col-6 u-col-lg-8 u-offset-lg-1'>
                <div className="u-d-lg-block t-color-white u-uppercase t-title-1">search</div>
              </div>
              <div className='u-col-6 u-col-lg-1 u-offset-lg-1'>
                <SimpleTextIconButton className='u-float-r' text='Close' svgId='cross-icon' negative onClick={ () => { setTimeout(AppActions.closeSearch) } } />
              </div>
            </div>
            <SearchLayer isActive={this.state.isOpen} ref={this.searchLayer} dark />
          </div>
        </div>
        <div onClick={() => { setTimeout(AppActions.closeSearch) }} className={`${styles.background} u-absolute u-z-index--1 u-pos-tl`}></div>
      </div>
    )
  }
  componentDidMount() {
    AppStore.on(AppConstants.OPEN_SEARCH, this.openSearch)
    AppStore.on(AppConstants.CLOSE_SEARCH, this.closeSearch)
    window.addEventListener('resize', this.resize)
  }
  componentDidUpdate() {
    if (!this.state.isOpen) this.parentTimeout = setTimeout(() => { transform(this.parent.current, `translateY(${-999999}px)`) }, 400)
    else transform(this.parent.current, `translateY(${0}px)`)
    this.searchLayer.current.reset()
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (!nextState.isOpen && !this.state.isOpen) return false
    if (nextState.isOpen === this.state.isOpen) return false
    return true
  }
  componentWillUnmount() {
    clearTimeout(this.parentTimeout)
    AppStore.off(AppConstants.OPEN_SEARCH, this.openSearch)
    AppStore.off(AppConstants.CLOSE_SEARCH, this.closeSearch)
    window.removeEventListener('resize', this.resize)
  }
  openSearch = () => {
    this.setState({isOpen: true})
  }
  closeSearch = () => {
    this.setState({isOpen: false, productSearchResults: [], value: ''}, this.searchLayer.current.reset())
  }
}

export default SearchPanel
