import React, { Component } from 'react'
import styles from './index.module.scss'

let feedback = false

class Input extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.state = {
      value: this.props.value
    }
  }
  setCustomValidity(msg) {
    this.feedback.textContent = msg
    feedback = true
  }
  onChange = event => {
    this.setState({ value: event.target.value })
    if (feedback) {
      this.feedback.textContent = ''
      feedback = false
    }
    if (this.props.onChange) this.props.onChange(event.target.value)
  }
  onFocus = () => {
    if (this.props.onFocus) this.props.onFocus()
  }
  onBlur = () => {
    if (this.props.onBlur) this.props.onBlur()
  }
  setValueExplicit = (value) => {
    this.setState({ value })
    this.input.current.blur()
  }
  render() {
    const inputClass = this.props.fullBorder ? styles.inputFull : styles.input
    return (
      <div className={`${this.props.className} u-relative`}>
        <input
          ref={this.input}
          className={`${inputClass} ${this.props.negative ? `t-color-white ${styles.negative}` : `t-color-black`} t-paragraph-1 u-fit-w u-block u-pad-t--10 form-control`}
          id={this.props.id}
          type={this.props.type}
          value={this.state.value}
          placeholder={this.props.placeholder}
          required={this.props.required}
          maxLength={this.props.maxLength ? this.props.maxLength : 524288}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <div className={`${styles.feedback} invalid-feedback t-paragraph-0`} ref={el => this.feedback = el}></div>
      </div>
    )
  }
}

Input.defaultProps = {
  className: '',
  value: '',
  required: false
}

export default Input
