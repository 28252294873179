import React, { Component } from 'react'
import styles from './index.module.scss'

class BackgroundBtn extends Component {
  constructor(props) {
    super(props)
  }
  onClick = () => {
    if (this.props.disable != 'true' && this.props.type !== 'submit') this.props.onClick()
  }
  render() {
    const { className, negative, highlighted, text, fullBlack, fullWhite, disable, icon, size, ...other } = this.props
    return (
      <button
        className={`${styles.button} ${className ? className : styles.paddingInside} ${size === 'md' ? styles.sizeMedium : styles.sizeLarge} ${negative ? styles.negative : ''} ${fullBlack ? styles.fullBlack : ''} ${fullWhite ? styles.fullWhite : ''} ${disable === 'true' ? styles.disable : ''} u-inline-block t-title-1 u-uppercase u-user-select--none u-text-center ${highlighted ? styles.highlighted : ''}`}
        {...other}
        onClick={this.onClick}
      >
        { !icon &&
        text
        }

        { icon &&
        <p className={`${styles.iconParent} u-flex`}>
          { text }
          <svg style={icon.style} className={`${styles.icon} ${icon.className}`}>
            <use xlinkHref={`#${icon.id}-icon`} />
          </svg>
        </p>
        }

      </button>
    )
  }
}

BackgroundBtn.defaultProps = {
  size: 'lg',
  fullWhite: false,
  fullBlack: false,
  highlighted: false,
  disable: 'false',
  className: ''
}

export default BackgroundBtn
