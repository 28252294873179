import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import Link from 'Components/Utils/Link'
import { imagePlaceholder } from 'Components/Utils/helpers'
import scrolltop from 'simple-scrolltop'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import MenuPanel from 'Components/UI/MenuPanel'
import MainBtn, { NORMAL } from 'Components/UI/MainBtn'
import styles from './index.module.scss'

class Header extends Component {
  constructor(props) {
    super(props)
    this.storiesPanel = React.createRef()
    this.scroll = 0
    this.state = {
      storiesAreOpen: false,
      menuOpen: false,
      headerIsCollapsed: false,
      headerIsLocked: false,
      totalBagItems: AppStore.getBag().length,
      totalFavoriteItems: AppStore.getFavorites().length
    }
  }
  componentDidMount() {
    AppStore.on(AppConstants.EXPAND_HEADER, this.onExpandHeader)
    AppStore.on(AppConstants.COLLAPSE_HEADER, this.onCollapseHeader)
    AppStore.on(AppConstants.ADD_TO_BAG, this.updateTotalBagItems)
    AppStore.on(AppConstants.ADD_TO_FAVORITES, this.updateTotalBagItems)
    AppStore.on(AppConstants.REMOVE_FROM_BAG, this.updateTotalBagItems)
    AppStore.on(AppConstants.REMOVE_FROM_FAVORITES, this.updateTotalBagItems)
    AppStore.on(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateTotalBagItems)
    AppStore.on(AppConstants.UPDATE_PATH, this.onUpdatePath)
    AppStore.on(AppConstants.APP_START, this.onUpdatePath)
    AppStore.on(AppConstants.OPEN_BURGER, this.onOpenBurger)
    AppStore.on(AppConstants.CLOSE_BURGER, this.onCloseBurger)
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('mousemove', this.onMouseMove)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.EXPAND_HEADER, this.onExpandHeader)
    AppStore.off(AppConstants.COLLAPSE_HEADER, this.onCollapseHeader)
    AppStore.off(AppConstants.ADD_TO_BAG, this.updateTotalBagItems)
    AppStore.off(AppConstants.ADD_TO_FAVORITES, this.updateTotalBagItems)
    AppStore.off(AppConstants.REMOVE_FROM_BAG, this.updateTotalBagItems)
    AppStore.off(AppConstants.REMOVE_FROM_FAVORITES, this.updateTotalBagItems)
    AppStore.off(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateTotalBagItems)
    AppStore.off(AppConstants.UPDATE_PATH, this.onUpdatePath)
    AppStore.off(AppConstants.APP_START, this.onUpdatePath)
    AppStore.off(AppConstants.OPEN_BURGER, this.onOpenBurger)
    AppStore.off(AppConstants.CLOSE_BURGER, this.onCloseBurger)
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('mousemove', this.onMouseMove)
    clearTimeout(this.scrollTimeout)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.storiesAreOpen && !this.state.storiesAreOpen) {
      setTimeout(() => {
        if (this.storiesPanel.current) this.storiesPanel.current.classList.add(styles.storiesPanelHide)
      }, 700)
    }
  }
  onScroll = (e) => {
    if (this.state.headerIsLocked) return
    const scrollPos = scrolltop()
    if (scrollPos > AppConstants.HEADER_POS_MINIMIZE) {
      if (!AppStore.headerIsCollapsed) this.toCollapseHeader()
    } else {
      if (AppStore.headerIsCollapsed) setTimeout(AppActions.expandHeader)
    }
    this.scroll = scrollPos
    clearTimeout(this.scrollTimeout)
    this.scrollTimeout = setTimeout(() => { if (!AppStore.headerIsCollapsed && scrollPos > AppConstants.HEADER_POS_MINIMIZE) this.toCollapseHeader() }, 2500)
  }
  onMouseMove = (e) => {
    if (this.state.headerIsLocked) return
    const scrollPos = scrolltop()
    if (AppStore.headerIsCollapsed) {
      if (WindowStore.Mouse.y < AppConstants.HEADER_POS_MINIMIZE - 20) setTimeout(AppActions.expandHeader)
    } else {
      if (WindowStore.Mouse.y > AppConstants.HEADER_HEIGHT && scrollPos > AppConstants.HEADER_POS_MINIMIZE) this.toCollapseHeader()
    }
  }
  onExpandHeader = () => {
    this.setState({ headerIsCollapsed: false })
  }
  onCollapseHeader = () => {
    this.setState({ headerIsCollapsed: true, storiesAreOpen: false })
  }
  toCollapseHeader = () => {
    if (this.state.storiesAreOpen) return
    setTimeout(AppActions.collapseHeader)
  }
  updateTotalBagItems = () => {
    this.setState({
      totalBagItems: AppStore.getBag().length,
      totalFavoriteItems: AppStore.getFavorites().length
    })
  }
  onUpdatePath = () => {
    const newRoute = AppStore.route.new
    if (newRoute.type === AppConstants.CHECKOUT) this.setState({ headerIsLocked: true })
    else this.setState({ headerIsLocked: false, storiesAreOpen: false })
  }
  onOpenBurger = () => {
    this.setState({ menuOpen: true })
  }
  onCloseBurger = () => {
    this.setState({ menuOpen: false })
  }
  onBurgerClick = () => {
    if (AppStore.burgerIsOpened) setTimeout(AppActions.closeBurger)
    else setTimeout(AppActions.openBurger)
  }
  onToggleBag = () => {
    if (WindowStore.getViewType() !== AppConstants.DESKTOP) {
      navigate('bag')
      return
    }
    if (AppStore.bagIsOpened) setTimeout(AppActions.closeBag)
    else setTimeout(AppActions.openBag)
  }
  onToggleFavorite = () => {
    if (WindowStore.getViewType() !== AppConstants.DESKTOP) {
      navigate('favorites')
      return
    }
    if (AppStore.accountIsOpened) setTimeout(AppActions.closeAccount)
    else setTimeout(AppActions.openAccount)
  }
  openStoriesPanel = () => {
    clearTimeout(this.overStoriesTimeout)
    if (!this.state.storiesAreOpen) this.setState({ storiesAreOpen: true })
  }
  onStoriesPanelMouseLeave = () => {
    clearTimeout(this.overStoriesTimeout)
    this.setState({ storiesAreOpen: false })
  }
  onMouseOverStoriesLink = () => {
    this.overStoriesTimeout = setTimeout(() => {
      this.setState({ storiesAreOpen: true })
    }, 300)
  }
  onMouseOverOtherLink = () => {
    clearTimeout(this.overStoriesTimeout)
    if (this.state.storiesAreOpen) this.setState({ storiesAreOpen: false })
  }
  render() {
    const storiesPanelStyle = this.state.storiesAreOpen ? styles.storiesPanelOpen : ''
    const windowW = WindowStore.Size.w
    const collapseClass = this.state.headerIsCollapsed && windowW > AppConstants.MQ_MEDIUM ? styles.collapse : ''
    const lockedClass = this.state.headerIsLocked ? `${styles.locked}` : ''
    const latestItems = [
      { id: 'red', to: '/red', name: 'Stella Luna x (RED)', showGif: true, showAsNew: true },
      { id: 'stella-stellar-collection', to: '/edit/stella-stellar-collection', name: 'A Stellar Stella', showGif: true, showAsNew: false },
      { id: 'play', to: '/edit/play', name: 'Play', showGif: false, showAsNew: false },
      { id: 'chained-up', to: '/edit/chained-up', name: 'Chained Up', showGif: false, showAsNew: false },
      { id: 'stella-summer', to: '/edit/stella-summer', name: 'Stella Summer', showGif: false, showAsNew: false },
      { id: 'cigarette-addiction', to: '/edit/cigarette-addiction', name: 'Cigarette Addiction', showGif: false, showAsNew: false }
    ]
    const latest = latestItems.map(item => {
      return (
        <li key={`latest-menu-${item.id}`} className={`t-title-1 t-paragraph-2 u-col-5`}>
          { item.showGif &&
          <Link to={`${item.to}`}>
            <div className={'u-relative u-fit'}>
              <div>
                { this.state.storiesAreOpen && <img src={`/images/menu/${item.id}.gif`} /> }
                { !this.state.storiesAreOpen && <img src={imagePlaceholder} /> }
              </div>
              <div className={`u-text-center`}><div className={`u-inline-block`}>{item.name}</div></div>
            </div>
          </Link>
          }
        </li>
      )
    })
    const storiesPanelList = latestItems.map(item => {
      return (
        <li key={`stories-latest-menu-${item.id}`} className="t-title-1 t-paragraph-2">
          <Link to={`${item.to}`}>
            { item.name }
            { item.showAsNew && <span className={`t-sub t-bg-red-campaign u-mrg-l--half`}>New</span> }
          </Link>
        </li>
      )
    })
    return (
      <StaticQuery
      query={graphql`
        query {
          allMenuJson {
            edges {
              node {
                id
                name
                slug
                permalink
                showInMenu
                cat_id
                color
              }
            }
          }
        }
      `}
      render={data => {
        const bag = AppStore.getBag()
        const collections = data.allMenuJson.edges
        const bagText = bag.length < 1 ? '' : `${bag.length}`
        const menuItems = collections.filter(item=>item.node.showInMenu).map((collection) => {
          const color = collection.node.color ? collection.node.color : 'black'
          return (
            <div onMouseEnter={this.onMouseOverOtherLink} key={`collection-item-${collection.node.slug}`}>
              <MainBtn
                defaultState={ NORMAL }
                className={`u-pad-x u-user-select--none`}
                onClick={() => { navigate(`/${collection.node.permalink}`) }}
                color={color} text={collection.node.name}
              />
            </div>
          )
        })
        menuItems.unshift(
          <div onMouseEnter={this.onMouseOverStoriesLink} onClick={this.openStoriesPanel} key={`collection-item-stories`}>
            <MainBtn
              defaultState={ NORMAL }
              className="u-pad-x u-user-select--none"
              onClick={() => {}}
              color={`black`}
              text={'Stories'}
            />
          </div>
        )
        return (
          <header className={`${styles.parent} ${collapseClass} ${lockedClass} u-container-fluid u-text-center u-fixed u-pos-tl u-fit-w u-z-index--9980`}>
            { this.state.headerIsLocked &&
            <div className={`${styles.lockedPanel} u-fit u-z-index--5 u-fixed`}>
              <div className={`u-flex u-fit u-col-lg-10 u-offset-lg-1 u-pad-x--lg`}>
                <Link to="/bag" className={`${styles.backToBag} t-color-white t-title-1`}>
                  <svg className="">
                    <use xlinkHref='#arrow-breadcrumb' />
                  </svg>
                  <span className={`u-pad-l--10`}>Back to bag</span>
                </Link>
                <Link to="/" className={`${styles.logoParent}`}>
                  <svg className={`${styles.logoWhite} u-fit`}>
                    <use xlinkHref='#stellaluna-logo' />
                  </svg>
                </Link>
              </div>
            </div>
            }
            { !this.state.headerIsLocked &&
            <div className={`u-fit u-z-index--5 u-absolute`}>
              <div className={`${styles.topPart} u-flex u-col-lg-10 u-offset-lg-1 u-z-index--1`}>

                <button onClick={() => {setTimeout(AppActions.openSearch)}} className={`${styles.button} search u-d-none u-d-lg-block u-uppercase search-btn`}>search</button>

                <button
                  className={`${styles.button} ${styles.burgerParent} ${this.state.menuOpen ? styles.isBurgerActive : ''} u-relative u-d-lg-none`}
                  onClick={this.onBurgerClick}>
                  <div className={`${styles.burgerMenu} u-absolute`}>
                    <div className={styles.burgerInner}></div>
                  </div>
                </button>

                <Link to="/search" className={`${styles.searchParent} u-absolute u-d-lg-none`}>
                  <div className={`${styles.searchMenu} u-absolute`}>
                    <svg className="u-fit">
                      <use xlinkHref='#search-icon' />
                    </svg>
                  </div>
                </Link>

                <Link to="/" className={styles.logo}>
                  <svg className={`u-fit`}>
                    <use xlinkHref='#stellaluna-logo' />
                  </svg>
                </Link>

                <button onClick={() => {setTimeout(AppActions.openAccount)}} className={`${styles.button} ${styles.accountBtn} u-uppercase u-d-none u-d-lg-inline-block`}>my account</button>

                <button className={`${styles.favoriteButton} ${styles.button} u-uppercase`} onClick={this.onToggleFavorite}>
                  <span className={`${styles.favoriteIcon}`}>
                    <svg className="u-fit">
                      <use xlinkHref='#heart-icon' />
                    </svg>
                  </span>
                </button>

                <button className={`${styles.bagButton} ${styles.button} u-uppercase`} onClick={this.onToggleBag}>
                  <span className={`${styles.bagText} u-inline-block t-paragraph-0`}>{bagText}</span>
                <span className={`${styles.bagIcon} u-inline-block`}>
                    <svg className="u-fit">
                      <use xlinkHref='#bag-icon' />
                    </svg>
                  </span>
                </button>
              </div>
              <div className={`${styles.bottomPart} u-d-none u-d-lg-flex`}>{menuItems}</div>
              <div className={`${styles.background} u-fit u-absolute u-z-index--back`}></div>
            </div>
            }
            <div ref={this.storiesPanel} onMouseLeave={this.onStoriesPanelMouseLeave} className={`${styles.storiesPanel} ${storiesPanelStyle} u-absolute u-z-index--4`}>
              <div className={`u-row u-text-left`}>
                <div className={`${styles.storiesCol} u-col-2 u-offset-3`}>
                  <div className={`t-title-1 u-mrg-b--20 t-color-gray`}>Stories</div>
                  <ul className={`${styles.storyTitles}`}>{ storiesPanelList }</ul>
                </div>
                <div className={`u-col-4 u-offset-1`}>
                  <div className={`t-title-1 u-mrg-b--20 t-color-gray`}>Latest</div>
                  <ul className={`u-row`}>{latest}</ul>
                </div>
              </div>
            </div>
            <MenuPanel
              className={`u-z-index--1`}
              totalFavoriteItems={this.state.totalFavoriteItems}
              open={this.state.menuOpen}
              onCloseClick={this.onBurgerClick}
              collections={collections}
              latestItems={latestItems}
            />
          </header>
        )
      }}
    />
    )
  }
}

export default Header
