import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import styles from './index.module.scss'
import ProductItem from 'Components/UI/ProductItem'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import MainBtn from 'Components/UI/MainBtn'
import SimpleTextIconButton from 'Components/UI/SimpleTextIconButton'
import transform from 'dom-css-transform'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import SummaryInformation from 'Components/UI/SummaryInformation'
import Price from 'Components/Utils/FormatPrice'

class BagPanel extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.state = {
      openedOnce: false,
      promo: undefined,
      isOpen: false,
      products: []
    }
  }
  resize = () => {
    if (this.productItemRefs.length > 0) this.productItemRefs.forEach(product => product.current.resize())
  }
  componentDidMount() {
    AppStore.on(AppConstants.OPEN_BAG, this.openBag)
    AppStore.on(AppConstants.CLOSE_BAG, this.closeBag)
    AppStore.on(AppConstants.ADD_TO_BAG, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.REMOVE_FROM_BAG, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.UPDATE_BAG_PRODUCT_SIZE, this.updateCurrentBagProducts)
    AppStore.on(AppConstants.APPLY_PROMO_CODE, this.didPromoCodeApply)
    window.addEventListener('resize', this.resize)
    this.updateCurrentBagProducts()
    if (AppStore.promoCodes && AppStore.promoCodes.length > 0) {
      this.setState({ promo: AppStore.promoCodes[0] })
    }
  }
  componentDidUpdate() {
    if (!this.state.isOpen) this.parentTimeout = setTimeout(() => { if (this.parent.current) transform(this.parent.current, `translateY(${999999}px)`) }, 700)
    else transform(this.parent.current, `translateY(${0}px)`)
  }
  componentWillUnmount() {
    clearTimeout(this.parentTimeout)
    AppStore.off(AppConstants.OPEN_BAG, this.openBag)
    AppStore.off(AppConstants.CLOSE_BAG, this.closeBag)
    AppStore.off(AppConstants.ADD_TO_BAG, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.REMOVE_FROM_BAG, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.UPDATE_BAG_PRODUCT_SIZE, this.updateCurrentBagProducts)
    AppStore.off(AppConstants.APPLY_PROMO_CODE, this.didPromoCodeApply)
    window.removeEventListener('resize', this.resize)
  }
  updateCurrentBagProducts = () => {
    const currentProducts = AppStore.getBag()
    this.setState({ products: currentProducts }, this.resize)
  }
  openBag = () => {
    this.setState({isOpen: true, openedOnce: true})
  }
  closeBag = () => {
    this.setState({isOpen: false})
  }
  onEditItemClick = (item) => {
    navigate(`/${item.permalink}`)
  }
  onProductItemRemoveClick = (product) => {
    setTimeout(AppActions.removeFromBag, 0, product)
  }
  onCheckoutClick = () => {
    navigate(`/bag`)
  }
  didPromoCodeApply = (data) => {
    this.setState({ promo: data })
  }
  render() {
    const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
    this.productItemRefs = []
    const openClass = this.state.isOpen ? styles.open : ''
    let totalAmount = 0
    const products = this.state.products.map((item, index) => {
      const { product } = item
      totalAmount += parseInt(product.price, 10)
      const productRef = React.createRef()
      if (this.state.openedOnce) this.productItemRefs.push(productRef)
      return (
        <div key={`bag-product-item-${product.id}-${index}`} className='u-col-6 u-col-lg-3'>
          <ProductItem
            baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
            data={item}
            index={0}
            ref={productRef}
            className=''
            extraClasses={'u-down-scale--1'}
            showFavoriteIcon={false}
            showRemoveIcon={true}
            showOtherColors={false}
            showInfo={true}
            lockSale={!isCustomer}
            buttonText={'Edit'}
            pageToGo={`/${product.permalink}`}
            buttonOnClick={this.onEditItemClick}
            removeOnClick={this.onProductItemRemoveClick}
            miniVersion={true}
          />
        </div>
      )
    })
    let bagMessage
    if (this.state.products.length === 0) bagMessage = <div className={`${styles.bagMessageHolder}`}><p className={`u-mrg-b--40 t-title-1`}>Your bag is empty</p><MainBtn color='white' text='continue shopping' onClick={() => { setTimeout(AppActions.closeBag) }} /></div>
    else bagMessage = <MainBtn onClick={() => { navigate(`/bag`) }} color={`white`} text={`View Bag`} />
    return (
      <StaticQuery
        query={graphql`
          query {
            dataJson {
              PRIVATE_SALE
            }
          }
        `}
        render={data => {
          const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
          const isPrivateSale = AppStore.isPrivateSale
          const lockSale = !isCustomer && isPrivateSale
          return (
            <div ref={this.parent} className={`${styles.parent} ${openClass} u-fixed u-fit u-z-index--9981`}>
              <div className={`${styles.container} u-absolute u-fit-w u-pos-tl u-z-index--2`}>
                <div className='u-d-none u-d-lg-block u-container-fluid u-text-center u-fit'>
                  <div className='u-flex u-col-lg-10 u-offset-lg-1 u-height--50'></div>
                  <div className='u-row'>
                    <div className='u-flex u-col-lg-8 u-offset-lg-1'>
                      <div className='u-d-lg-block t-color-white u-uppercase t-title-1'>{bagMessage}</div>
                    </div>
                    <div className='u-col-lg-1 u-offset-lg-1'>
                      <SimpleTextIconButton className='u-float-r' text='Close' svgId='cross-icon' negative onClick={() => { setTimeout(AppActions.closeBag) }} />
                    </div>
                  </div>
                  <div className='u-height--50'></div>
                </div>
                <div className={`${styles.wrapper} u-container-fluid u-fit`}>
                  <div className='u-row'>
                    {this.state.products.length > 0 &&
                      <div className={`u-d-lg-none u-pad-y--large u-text-center u-fit`}>
                        <p className='t-title-1'>you have {this.state.products.length} item{this.state.products.length === 1 ? '' : 's'} in your bag <br/> for a total amount of € {Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, totalAmount )}</p>
                        <div className='u-height--40'></div>
                        <MainBtn onClick={() => { navigate(`/bag`) }} color={`white`} text={`View Bag`} />
                      </div>
                    }
                    {this.state.products.length === 0 && 
                      <div className={`u-d-lg-none u-pad-y--large u-text-center u-fit t-title-1`}>
                        {bagMessage}
                      </div>
                    }
                    <div className='u-col-lg-7 u-offset-lg-1'>
                      <div className='u-container-fluid u-fit'>
                        { this.state.products.length > 0 && 
                          <div className={`u-col-12 u-col-lg-11`}>
                            { this.state.openedOnce && <div className={`${styles.productsContainer} u-row`}>{products}</div>}
                          </div>
                        }
                        <div className={`u-d-none u-d-lg-block u-col-lg-10 u-offset-lg-1 u-height--${this.state.products.length === 0 ? 0 : 120}`}></div>
                      </div>
                    </div>
                    <div className='u-d-none u-d-lg-flex u-col-lg-3'>
                      {this.state.products.length > 0 &&
                        <div className='u-col-lg-12'>
                          <div className='u-row'>
                            <div className='u-col-lg-8 t-color-white t-paragraph-1'>In your bag</div>
                            <div className='u-col-lg-4'>
                              <div className='u-float-r t-color-white t-paragraph-1'>{this.state.products.length} Item{this.state.products.length === 1 ? '' : 's'} </div>
                            </div>
                          </div>
                          <div className='u-height--10'></div>
                          <div className='u-separator t-bg-gray'></div>
                          <div className='u-height--20'></div>
                          <SummaryInformation promo={this.state.promo} color={`white`} fontStyle={`t-paragraph-1`} lockSale={lockSale} />
                          <div className='u-height--40'></div>
                          <BackgroundBtn
                            className='u-pad-x u-fit-w'
                            text='Checkout'
                            onClick={this.onCheckoutClick}
                            highlighted
                          />
                          <div className={`u-fit-w u-height--60`} />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <BackgroundBtn
                className={`${styles.checkoutButton} u-d-lg-none u-fixed u-pos-b--0 u-pos-l--0 u-fit-w u-z-index--2`}
                onClick={this.onCheckoutClick}
                text='Checkout'
                highlighted
              />
              <div onClick={() => { setTimeout(AppActions.closeBag) }} className={`${styles.background} u-d-none u-d-lg-block u-absolute u-fit-vwh u-z-index--1 u-pos-tl`}></div>
            </div>
          )
        }}
      />
    )

  }
}

export default BagPanel
