import React, { Component } from 'react'
import SimpleTextIconButton from 'Components/UI/SimpleTextIconButton'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import transform from 'dom-css-transform'
import styles from './index.module.scss'

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.defaultTime = 2500
    this.state = {
      time: this.defaultTime,
      isOpen: false,
      data: undefined
    }
  }
  render() {
    const openClass = this.state.isOpen ? styles.open : ''
    const type = this.state.data ? this.state.data.type : 'normal'
    const stayOpen = this.state.data ? this.state.data.stayOpen : false
    const color = this.getType(type)
    const backgroundColor = `t-bg-${color}`
    const fillColor = `t-fill-${color}`
    const icon = `${color}-icon`
    const message = this.state.data ? this.state.data.message : ''
    const isTurning = type === LOADING ? true : false
    return (
      <div ref={this.parent} className={`${styles.parent} ${openClass} u-button u-fixed u-fit-w u-z-index--9982 u-pos-tl`} onMouseMove={stayOpen ? () => {} : this.resetNotificationTimeout} onClick={ () => { setTimeout(AppActions.closeNotification) } }>
        <div className={`${styles.container} ${backgroundColor} u-fit-w`}>
          <div className='u-container-fluid u-text-center u-fit'>
            <div className={`${styles.holder} u-row`}>
              <div className={`${styles.message} u-d-lg-block t-color-white t-paragraph-0 t-bold u-text-left u-offset-lg-1 u-col-9 u-col-lg-9`}>
                <div className={`${styles.icon} u-float-l`}>
                  <svg className={`u-fit t-fill-color-white ${fillColor} ${isTurning ? 'u-infinite-rotate' : ''}`}>
                    <use xlinkHref={`#${icon}`} />
                  </svg>
                </div>
                <div className={`${styles.messageInner} u-float-l`}>{message}</div>
              </div>
              <div className={`${styles.closeParent} u-col-0 u-col-lg-1 u-text-right`}>
                <SimpleTextIconButton className={`${styles.closeIcon} u-inline-block`} text='' svgId='cross-icon' negative onClick={ () => {} } />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    AppStore.on(AppConstants.OPEN_NOTIFICATION, this.openNotification)
    AppStore.on(AppConstants.CLOSE_NOTIFICATION, this.closeNotification)
  }
  componentDidUpdate() {
    clearTimeout(this.parentTimeout)
    clearTimeout(this.closeNotificationTimeout)
    if (!this.state.isOpen) this.parentTimeout = setTimeout(() => { if (this.parent.current) transform(this.parent.current, `translateY(${-999999}px)`) }, 400)
    else transform(this.parent.current, `translateY(${0}px)`)
    if (this.state.data && !this.state.data.stayOpen) this.resetNotificationTimeout()
  }
  componentWillUnmount() {
    clearTimeout(this.parentTimeout)
    clearTimeout(this.closeNotificationTimeout)
    AppStore.off(AppConstants.OPEN_NOTIFICATION, this.openNotification)
    AppStore.off(AppConstants.CLOSE_NOTIFICATION, this.closeNotification)
  }
  getType = (type) => {
    switch (type) {
      case HEART: return 'heart'
      case BAG: return 'bag'
      case WARNING: return 'warning'
      case ERROR: return 'error'
      case SUCCESS: return 'success'
      case LOADING: return 'loading'
      default: return 'normal'
    }
  }
  openNotification = (data) => {
    const time = data.time ? data.time : this.defaultTime
    this.setState({isOpen: true, data, time })
  }
  resetNotificationTimeout = () => {
    clearTimeout(this.closeNotificationTimeout)
    this.closeNotificationTimeout = setTimeout(AppActions.closeNotification, this.state.time)
  }
  closeNotification = () => {
    this.setState({isOpen: false})
  }
}

export const HEART = 'HEART_NOTIFICATION'
export const BAG = 'BAG_NOTIFICATION'
export const WARNING = 'WARNING_NOTIFICATION'
export const ERROR = 'ERROR_NOTIFICATION'
export const SUCCESS = 'SUCCESS_NOTIFICATION'
export const LOADING = 'LOADING_NOTIFICATION'

export default Notifications
