import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import SVGSymbols from 'Components/SVGSymbols'
import Header from 'Components/Header'
import CookiesPanel from 'Components/UI/CookiesPanel'
import LockedScreenMessagesPanel from 'Components/UI/LockedScreenMessagesPanel'
import PopupLogin from 'Components/UI/PopupLogin'
import Newsletter from 'Components/Newsletter'
import Footer from 'Components/Footer'
import AppActions from 'AppActions'
import SearchPanel from 'Components/UI/SearchPanel'
import BagPanel from 'Components/UI/BagPanel/'
import AccountPanel from 'Components/UI/AccountPanel'
import Notifications from 'Components/UI/Notifications'
import Transition from 'Components/Transition'
import '../styles/index.scss'

class Layout extends Component {
  componentDidMount() {
    AppActions.appStart({ isPrivateSale: this.isPrivateSale })
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            dataJson {
              PRIVATE_SALE
            }
          }
        `}
        render={data => {
          this.isPrivateSale = data.dataJson.PRIVATE_SALE
          return (
            <div id={`layout-container`}>
              <SVGSymbols />
              <LockedScreenMessagesPanel />
              <Notifications />
              <PopupLogin />
              <CookiesPanel />
              <Header />
              <SearchPanel />
              <BagPanel />
              <AccountPanel />
              <div className={`u-container-fluid o-wrapper`}>
                <Transition>{this.props.children}</Transition>
              </div>
              <Newsletter />
              <Footer />
            </div>
          )
        }}
      />
    )
  }
}

export default Layout
