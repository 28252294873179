import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Link from 'Components/Utils/Link'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import Form from 'Components/UI/Form'
import titles from 'Components/UI/Misc/titles'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import Checkbox from 'Components/UI/Checkbox'
import styles from './index.module.scss'

class LoginRegisterForm extends Component {
  constructor(props) {
    super(props)
    const isRegister = this.props.toRegister ? true : false
    const isLogin = this.props.toRegister ? false : true
    this.checkbox = React.createRef()
    this.newsletterCheckbox = React.createRef()
    this.state = {
      isEditingRegister: isRegister,
      isEditingLogin: isLogin,
      registerMessage: ''
    }
  }
  componentDidMount() {
    AppStore.on(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.on(AppConstants.REGISTER, this.onRegisterCustomer)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.off(AppConstants.REGISTER, this.onRegisterCustomer)
  }
  onRegisterCustomer = (data) => {
    if (data.state !== 'success') this.setState({ registerMessage: data.message })
    else navigate('/my-account/login/')
  }
  onLoginCustomer = () => {
    if (this.props.onLoginCustomer) this.props.onLoginCustomer()
  }
  onEditRegister = () => {
    if (this.state.isEditingRegister) return
    this.setState({
      isEditingRegister: !this.state.isEditingRegister,
      isEditingLogin: false
    })
  }
  onEditLogin = () => {
    if(this.state.isEditingLogin) return
    this.setState({
      isEditingLogin: !this.state.isEditingLogin,
      isEditingRegister: false
    })
  }
  onLoginSubmit = (user) => {
    setTimeout(AppActions.login, 0, user)
  }
  onRegisterSubmit = (user) => {
    setTimeout(AppActions.register, 0, user)
  }
  render() {
    const { isEditingRegister, isEditingLogin } = this.state
    return (
      <div className={styles.parent}>
        { isEditingRegister &&
        <div className={`u-row`}>
          <div className={`u-col-lg-6`}>
            <p className={`u-inline-block t-title-2 u-uppercase`}>Create your account</p>
            <RegisterForm onSubmit={this.onRegisterSubmit} />
          </div>
          <div className='u-height--only-lg--60' />
          <div className={`${styles.sidePanel} u-col-lg-4 u-offset-lg-2 t-bg-mediumgray u-table`}>
            <p className={`t-color-black t-title-2 u-uppercase`}>Returning Customer?</p>
            <div className='u-height--20'/>
            <p className={`t-paragraph-0`}>Please log in if you previously set up an account. This will speed up the checkout process and save your purchase in your order history.</p>
            <div className='u-height--20'/>
            <BackgroundBtn
              className={`u-col-12`}
              text={`Send me to Login`}
              onClick={() => navigate('/my-account/login/')}
              negative
              highlighted
            />
          </div>
        </div>
        }
        { isEditingLogin &&
        <div className={`u-row`}>
          <div className={`${this.props.hideRegister ? 'u-col-lg-12' : 'u-col-lg-6'}`}>
            <p className={`u-inline-block t-title-2 u-uppercase u-pad-b--10`}>Returning Customer</p>
            { this.props.hideRegister && <p className={`t-paragraph-0 t-color-gray`}>Please log in if you previously set up an account. This will speed up the checkout process and save your purchase in your order history.</p>}
            <LoginForm onSubmit={this.onLoginSubmit} />
          </div>
          <div className='u-height--only-lg--60' />
          { !this.props.hideRegister &&
          <div className={`${styles.sidePanel} u-col-lg-4 u-offset-lg-2 t-bg-mediumgray`}>
            <p className={`t-color-black t-title-2 u-uppercase`}>DON'T HAVE AN ACCOUNT?</p>
            <div className='u-height--20'/>
            <p className={`t-paragraph-0`}>Having an account will give you access to:</p>
            <div className='u-height--10'/>
            <p className={`t-paragraph-0 t-color-gray`}>&bull; Saved items in your wishlist.</p>
            <p className={`t-paragraph-0 t-color-gray`}>&bull; Personalised recommendations.</p>
            <p className={`t-paragraph-0 t-color-gray`}>&bull; Order delivery updates and return management.</p>
            <div className='u-height--20'/>
            <BackgroundBtn
              className={`u-col-12`}
              text={`Create Account`}
              onClick={() => navigate('/my-account/register/')}
              negative
              highlighted
            />
          </div>
          }
        </div>
        }
      </div>
    )
  }
}


LoginRegisterForm.defaultProps = {
  hideRegister: false
}

export class RegisterForm extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.checkbox = React.createRef()
    this.state = {
      newsletterChecked: false
    }
    this.registerForm = [
      [
        {
          type: 'select',
          props: {
            id: 'genderTitle',
            className: 'u-col-4 u-col-lg-2 u-mrg-t--20',
            options: titles,
            placeholder: 'Title',
            value: '',
            required: true
          }
        },
        {
          type: 'input',
          props: {
            id: 'firstName',
            className: 'u-col-lg-9 u-offset-lg-1',
            type: 'text',
            placeholder: 'First Name',
            required: true
          }
        }
      ],
      {
        type: 'input',
        props: {
          id: 'lastName',
          className: 'u-col-lg-12',
          type: 'text',
          placeholder: 'Last Name',
          required: true
        }
      },
      {
        type: 'input',
        props: {
          id: 'registerMail',
          className: 'u-col-12',
          type: 'email',
          placeholder: 'Email Address',
          required: true
        }
      },
      {
        type: 'input',
        props: {
          id: 'registerPassword',
          className: 'u-col-12',
          type: 'password',
          placeholder: 'Password',
          required: true
        }
      },
      {
        type: 'input',
        props: {
          id: 'registerConfirmPassword',
          className: 'u-col-12',
          type: 'password',
          placeholder: 'Confirm password',
          required: true
        }
      }
    ]
  }
  onRegisterSubmit = () => {
    if (this.form.current.refs.registerPassword.state.value !== this.form.current.refs.registerConfirmPassword.state.value) {
      this.form.current.refs.registerPassword.setCustomValidity(`Passwords don't match`)
      this.form.current.refs.registerConfirmPassword.setCustomValidity(`Passwords don't match`)
      return
    }
    const user = {
      title: this.form.current.refs.genderTitle.state.value.value,
      email: this.form.current.refs.registerMail.state.value,
      first_name: this.form.current.refs.firstName.state.value,
      last_name: this.form.current.refs.lastName.state.value,
      password: this.form.current.refs.registerPassword.state.value,
      subscribe_to_newsletter: this.state.newsletterChecked
    }
    if (this.props.onSubmit) this.props.onSubmit(user)
  }
  onCheckboxChange = () => {
    this.setState({ newsletterChecked: this.checkbox.current.state.checked })
  }
  render() {
    const showAlternativeBtn = this.props.onClickAlternativeBtn ? true : false
    return (
      <div>
        <Form className={`u-remove-autofill`} items={this.registerForm} submit={this.onRegisterSubmit} ref={this.form}>
          <div className='u-row u-pad-t--20'>
            <Checkbox
              id='newsletter'
              className='t-paragraph-0 u-pad-b--20'
              value='newsletter'
              onChange={this.onCheckboxChange}
              label='Subscribe to receive Stella Luna e-mails with access to our latest collections, events and initiatives.'
              ref={this.checkbox}
              checked={this.state.newsletterChecked}
            />
            <BackgroundBtn
              className='u-col-12 u-col-lg-6'
              text='Create Account'
              type='submit'
              fullBlack
              highlighted
            />
            { showAlternativeBtn &&
            <BackgroundBtn
              className={`${styles.alternativeBtn} u-col-12 u-col-lg-4`}
              text='Login'
              onClick={this.props.onClickAlternativeBtn}
              negative
              fullBlack
              highlighted
            />
            }
          </div>
        </Form>
        <p className={`t-paragraph-0 t-color-gray u-pad-t--10`}>By creating an account you agree to the <a href='/terms-and-conditions' className={`u-simple-hyperlink`} target={`_blank`}>Terms & Conditions</a> and <a href='/privacy-statement' className={`u-simple-hyperlink`} target={`_blank`}>Privacy Statement</a></p>
      </div>
    )
  }
}

export class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.checkbox = React.createRef()
    this.form = React.createRef()
    this.state = {
      staySignedIn: true
    }
    this.loginForm = [
      {
        type: 'input',
        props: {
          id: 'loginMail',
          className: 'u-col-12',
          type: 'email',
          placeholder: 'Email Address',
          required: true
        }
      },
      {
        type: 'input',
        props: {
          id: 'loginPassword',
          className: 'u-col-12',
          type: 'password',
          placeholder: 'Password',
          required: true
        }
      }
    ]
  }
  onCheckboxChange = () => {
    this.setState({ staySignedIn: this.checkbox.current.state.checked  })
  }
  onLoginSubmit = () => {
    const user = {
      email: this.form.current.refs.loginMail.state.value,
      password: this.form.current.refs.loginPassword.state.value,
      remember: this.state.staySignedIn
    }
    if (this.props.onSubmit) this.props.onSubmit(user)
  }
  render() {
    const showAlternativeBtn = this.props.onClickAlternativeBtn ? true : false
    return (
      <div>
        <Form className={`u-remove-autofill`} items={this.loginForm} submit={this.onLoginSubmit} ref={this.form}>
          <div className='u-row u-pad-t--20'>
            <Checkbox
              id='stay_signed'
              className='t-paragraph-0 u-col-12 u-pad-b--20'
              value='Stay Signed'
              onChange={this.onCheckboxChange}
              label='Stay logged in'
              ref={this.checkbox}
              checked={this.state.staySignedIn}
            />
            <BackgroundBtn
              className='u-col-12 u-col-lg-6'
              text='Login'
              type='submit'
              fullBlack
              highlighted
            />
            { showAlternativeBtn &&
            <BackgroundBtn
              className={`${styles.alternativeBtn} u-col-12 u-col-lg-4`}
              text='Create Account'
              onClick={this.props.onClickAlternativeBtn}
              negative
              fullBlack
              highlighted
            />
            }
          </div>
        </Form>
        <p className={`t-paragraph-0 t-color-gray u-pad-t--10`}>
          <Link to={`/forgot-password`} className={`${styles.passwordButton}`}>Forgot your password?</Link>
        </p>
      </div>
    )
  }
}

export default LoginRegisterForm