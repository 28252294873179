import React, { Component } from 'react'
import styles from './index.module.scss'
import AppConstants from 'AppConstants'
import AppStore from 'AppStore'
import Analytics from 'Components/Utils/Analytics'
import { cancelablePromise } from 'Components/Utils/helpers'
import ProductItem from 'Components/UI/ProductItem'

class SearchLayer extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.searchInput = React.createRef()
    this.text = {
      default: 'Type here...'
    }
    this.state = {
      suggestions: [ 'Suede', 'Buckle', 'Ankle', 'Round', 'Red', 'Gold' ],
      value: '',
      isLoading: false,
      isFocus: false,
      productSearchResults: []
    }
  }
  render() {
    const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
    this.productRefs = []
    const products = this.state.productSearchResults.map((product, index) => {
      const productRef = React.createRef()
      this.productRefs.push(productRef)
      return (
        <div key={`search-product-item-${product.id}-${index}`} className='u-col-6 u-col-lg-3'>
          <ProductItem
            baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
            data={{product}}
            index={0}
            lockSale={!isCustomer}
            ref={productRef}
            miniVersion={false}
            pageToGo={`/${product.permalink}`}
            extraClasses={'u-down-scale--1'}
            />
        </div>
      )
    })
    const suggestions = this.state.suggestions
    const suggestionsDom = suggestions.map(suggestion => {
      return (
        <li key={`${suggestion}_sug`} className='u-inline-block u-uppercase u-mrg-r--20 t-color-gray u-uppercase t-title-1'>
          <button onClick={this.onSuggestionClicked} className='u-uppercase t-color-gray t-title-1'>{suggestion}</button>
        </li>
      )
    })
    const resultsStr = this.state.productSearchResults.length === 1 ? 'result' : 'results'
    const loaderActive = this.state.isLoading ? styles.loaderActive : ''
    const darkClass = this.props.dark ? styles.dark : ''
    let searchResultTxt = this.state.productSearchResults.length > 0 ? `we found ${this.state.productSearchResults.length} ${resultsStr}` : 'Sorry we found no results'
    if (this.state.isLoading) searchResultTxt = 'Searching...'
    return (
      <div ref={this.parent} className={`${styles.parent} ${darkClass} u-fit`}>
        <div className={`${styles.container} u-fit-w u-pos-tl u-z-index--2`}>
          <div className='u-container-fluid u-text-center u-fit u-pad-x--lg'>
            <div className='u-col-lg-10 u-offset-lg-1'>
              <div className='u-height--50'></div>
              <form className={`${styles.searchForm}`} onSubmit={(e) => {e.preventDefault()}} autoComplete='off'>
                <input
                  ref={this.searchInput}
                  type='search'
                  className='t-big-title'
                  placeholder={this.text.default}
                  value={this.state.value}
                  onFocus={this.inputOnFocus}
                  onBlur={this.inputOnBlur}
                  onChange={this.onSearchInputChange}
                />
              </form>
              <div className='u-height--20'></div>
              <div className={`${styles.loaderWrapper} ${loaderActive}`}>
                <img className={`${styles.spinner}`} src={`/images/spinner_${this.props.dark ? 'black' : 'white'}.gif`} />
                <div className={`${styles.searchResultText} u-text-left t-title-1 u-uppercase ${ this.state.value.length < 3 ? 'u-opacity--0' : 'u-opacity--100' }`}>{searchResultTxt}</div>
              </div>
              <div className='u-height--60'></div>
              <div className={`${styles.suggestions} u-text-left`}>
                <div className={`u-uppercase t-title-1`}>suggestions</div>
                <div className='u-height--20'></div>
                <ul className='u-col-lg-12'>
                  {suggestionsDom}
                </ul>
              </div>
            </div>
            <div className='u-height--40'></div>
          </div>
          <div className={`${styles.productsContainer} u-container-fluid u-fit`}>
            <div className={`u-row`}>{ products }</div>
            <div className='u-height--120'></div>
            <div className='u-height--120'></div>
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  componentDidUpdate(prevProps, prevState) {
    this.resize()
    if (this.state.value !== prevState.value) this.querySearch()
  }
  resize = () => {
    this.productRefs.forEach(item => item.current.resize())
  }
  onSearchInputChange = (e) => {
    e.preventDefault()
    if (this.searchQueryFetch) this.searchQueryFetch.cancel()
    const value = this.searchInput.current.value
    this.setState({value, productSearchResults: []})
  }
  onSuggestionClicked = (e) => {
    if (this.searchQueryFetch) this.searchQueryFetch.cancel()
    this.setState({value: e.currentTarget.textContent, productSearchResults: []})
  }
  querySearch = () => {
    if (this.searchQueryFetch) this.searchQueryFetch.cancel()
    const value = this.state.value
    if (value.length <= 2) {
      this.setState({ isLoading: false })
      return
    } else {
      this.setState({ isLoading: true })
    }
    Analytics.send('search', 'query', { search_string: value })
    Analytics.gEcom('search', { search_term: value })
    this.searchQueryFetch = cancelablePromise(fetch(`${AppConstants.API_URL}search?query=${value}`))
    this.searchQueryFetch.promise
    .then((res) => res.json())
    .then((data) => {
      this.setState({ productSearchResults: data, isLoading: false }, this.resize)
    })
    .catch(e => {})
  }
  reset = () => {
    this.setState({ productSearchResults: [], value: '', isLoading: false })
  }
  inputOnFocus = () => {
    if (this.state.value === this.text.default) {
      this.setState({value: '', isFocus: true, isLoading: false})
    }
  }
  inputOnBlur = () => {
    if (this.state.value.length < 2) {
      this.setState({value: '', isFocus: false, isLoading: false})
    }
  }
}

export default SearchLayer
