import React from 'react'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'
require('@gouch/to-title-case')

export const NORMAL = styles.normalState
export const HIGHLIGHTED = styles.highlightedState
export const ACTIVE = styles.activeState

const CircledIcon = (props) => {
  const url = props.url ? props.url : ''
  const onClick = (e) => {
    if (props.onClick === undefined) return
    e.preventDefault()
    props.onClick()
  }
  const bgColor = styles[`bgColor${props.bgColor.toTitleCase()}`]
  const iconColor = styles[`iconColor${props.iconColor.toTitleCase()}`]
  return (
    <Link to={url} onClick={onClick} className={`${props.defaultState} ${props.className}`}>
      <div className={`u-relative`}>
        <div className={`${styles.button} ${bgColor} ${iconColor}`}>
          <svg className={`${styles.svg} u-fit`}>
            <use xlinkHref={`#${props.svgId}`} />
          </svg>
        </div>
      </div>
    </Link>
  )
}

CircledIcon.defaultProps = {
  svgId: `cross-icon`,
  bgColor: `black`,
  iconColor: `white`,
  defaultState: HIGHLIGHTED
}

export default CircledIcon
