import {EventEmitter2} from 'eventemitter2'
import assign from 'object-assign'
import findIndex from 'lodash/findIndex'
import { WARNING, ERROR, SUCCESS, LOADING, BAG, HEART } from 'Components/UI/Notifications'
import Dispatcher from '../dispatcher'
import Analytics from 'Components/Utils/Analytics'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import { formatProductForGA, getBagItems } from 'Components/Utils/helpers'
import NProgress from 'nprogress'
import { preparingOrderMessage } from 'Components/UI/LockedScreenMessagesPanel/messages'
import { navigate } from 'gatsby'
const queryString = require('query-string')
const unixTime = require('unix-time')
const uuidv1 = require('uuid/v1')

NProgress.configure({ easing: 'cubic-bezier(0.390, 0.575, 0.565, 1.000)', speed: 600, showSpinner: false })

let localGet, localSet
localGet = require('Utils/localStorage').getItem
localSet = require('Utils/localStorage').setItem

const _getLocalStorageByType = (type) => {
  let currentArray = []
  const local = localGet(type)
  currentArray = local ? local : []
  return currentArray
}
const getBag = () => _getLocalStorageByType(AppConstants.BAG)
const getCookies = () => _getLocalStorageByType(AppConstants.COOKIES)
const getFavorites = () => _getLocalStorageByType(AppConstants.FAVORITES)
const getRecentlyViewed = () => _getLocalStorageByType(AppConstants.RECENTLY_VIEWED)
const getCustomer = () => {
  const customer = _getLocalStorageByType(AppConstants.CUSTOMER)
  customer.type = AppConstants.CUSTOMER
  return customer
}
const getGuestCustomer = () => {
  const customer = _getLocalStorageByType(AppConstants.GUEST_CUSTOMER)
  customer.type = AppConstants.GUEST_CUSTOMER
  return customer
}
const _emitUpdateCustomerData = (actionType, data, emitResponse={}) => {
  if (data.id) localSet(AppConstants.CUSTOMER, data)
  else localSet(AppConstants.CUSTOMER, {})
  setTimeout(AppActions.customerStatusChanged)
  Store.emit(actionType, emitResponse)
}
const _getCustomerType = () => {
  const customer = Store.getCustomer()
  if (customer && customer.id) return AppConstants.CUSTOMER
  else return AppConstants.GUEST_CUSTOMER
}
const _getAllBagItemsTotalAmount = () => {
  const bag = Store.getBag()
  const currentBag = bag && Array.isArray(bag) ? bag : []
  const isCustomer = Store.userIsValid(Store.getCustomer())
  const isPrivateSale = Store.isPrivateSale
  const lockSale = !isCustomer && isPrivateSale
  let total = 0
  let totalSave = 0
  let totalPromoCodesDiscount = 0
  const productsNotInSale = currentBag.filter(item => { return !item.product.sale.inSale || lockSale })
  currentBag.forEach((item) => {
    total += parseInt(item.product.price, 10)
    if (item.product && item.product.sale.inSale) {
      totalSave += item.product.sale.originalPrice - item.product.sale.price
    }
  })
  productsNotInSale.forEach(item => {
    if (Store.promoCodes.length > 0) {
      Store.promoCodes.forEach(promo => {
        const price = Number(item.product.price)
        // const discount = price * (promo.discount / 100)
        // const discount = Math.floor(((100 - promo.discount) / 100) * price)
        // console.log(item.product.title, 'price', price, 'discount', discount)
        // totalPromoCodesDiscount += discount
        totalPromoCodesDiscount += Math.floor((promo.discount / 100) * price)
      })
    }
  })
  // console.log('total', totalPromoCodesDiscount)
  return {
    total,
    totalPromoCodesDiscount,
    totalWithSale: total - totalSave,
    totalWithPromos: total - totalPromoCodesDiscount,
    totalWithSaleAndPromos: total - totalSave - totalPromoCodesDiscount,
    saved: totalSave,
    includeSale: totalSave > 0 ? true : false,
    promoCodes: Store.promoCodes
  }
}
const _isProductInFavorites = (id) =>{
  const favorites = Store.getFavorites()
  for (let index = 0; index < favorites.length; index++) {
    const element = favorites[index]
    if (element.product.id === id) return true
  }
  return false
}
const _getAllBagItemsNum = () => {
  const bag = Store.getBag()
  let num = bag.length
  return num
}
const _getCurrentCustomer = () => {
  const customerType = Store.getCustomerType()
  if (customerType === AppConstants.GUEST_CUSTOMER) return Store.getGuestCustomer()
  else return Store.getCustomer()
}
const _registerUser = (data) => {
  return fetch(`${AppConstants.API_URL}customer`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify(data) }))
  .then((res) => res.json())
}
const _userHasAcceptedCookies = () => {
  const cookies = getCookies()
  return cookies.length === 0 || !cookies.user_informed ? false : true
}
const _userSessionHasExpired = (customer) => {
  const timestamp = unixTime(new Date())
  return (customer.session.expiration < timestamp) ? true : false
}
const _userIsValid = (customer) => {
  if (customer && customer.id && !_userSessionHasExpired(customer)) return true
  else return false
}
const _checkURLParams = () => {
  const parsed = queryString.parse(location.search)
  if (parsed.notif) {
    switch (parsed.notif) {
      case 'password_changed_success':
        setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'Your password has been changed' })
        window.history.pushState(`login`, `Login`, `/my-account/login`)
      break
      case 'payment_success':
        setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'Thank you for your order. We will soon be sending you an e-mail confirming your Stella Luna purchase.', stayOpen: true })
        window.history.pushState(`home`, `Home`, `/`)
      break
      case 'payment_failed':
        setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'We’re sorry. There appears to be a payment authorization issue. Your card has not been charged. Please feel free to try again (perhaps with a different card, if possible). If the problem persists, please contact us at contact@stellaluna.paris', stayOpen: true })
        window.history.pushState(`home`, `Home`, `/bag`)
      break
    }
  }
}
const _getUpdatedCustomerFromServer = (id, actionType, item) => {
  fetch(`${AppConstants.API_URL}customer?id=${id}`, AppConstants.GET_OPTIONS)
  .then((res) => res.json())
  .then((data) => {
    if (data.id) _emitUpdateCustomerData(actionType, data, { state: 'success' })
    else Store.emit(actionType, item)
  })
  .catch(() => {})
}
const removeFromFavorites = (itemToRemove) => {
  const favorites = getFavorites()
  const itemToRemoveIndex = findIndex(favorites, (o) => { return o.product.id === itemToRemove.product.id })
  const newFavorites = [
    ...favorites.slice(0, itemToRemoveIndex),
    ...favorites.slice(itemToRemoveIndex + 1)
  ]
  localSet(AppConstants.FAVORITES, newFavorites)
}
const removeFromBag = (itemToRemove) => {
  const bag = getBag()
  const itemToRemoveIndex = findIndex(bag, (o) => { return o.uuid === itemToRemove.uuid })
  const newBag = [
    ...bag.slice(0, itemToRemoveIndex),
    ...bag.slice(itemToRemoveIndex + 1)
  ]
  localSet(AppConstants.BAG, newBag)
}
const updateBackProductSize = (data) => {
  const bag = getBag()
  const indexOfItemToChange = findIndex(bag, (o) => { return o.uuid === data.uuid })
  const newBag = [
    ...bag.slice(0, indexOfItemToChange),
    data,
    ...bag.slice(indexOfItemToChange + 1)
  ]
  localSet(AppConstants.BAG, newBag)
}
const pushNewItemInBag = (newItem) => {
  const bag = getBag()
  const itemToPush = { ...newItem, uuid: uuidv1() }
  const newBag = [...bag, itemToPush]
  localSet(AppConstants.BAG, newBag)
}
const pushNewFavorite = (newItem) => {
  const favorites = getFavorites()
  const newFavorites = [ ...favorites, newItem ]
  localSet(AppConstants.FAVORITES, newFavorites)
}
const closeAllPanels = () => {
  if (Store.searchIsOpened) setTimeout(AppActions.closeSearch)
  if (Store.bagIsOpened) setTimeout(AppActions.closeBag)
  if (Store.accountIsOpened) setTimeout(AppActions.closeAccount)
}
const Store = assign({}, EventEmitter2.prototype, {
  route: { new: '', old: '' },
  isPrivateSale: false,
  headerIsCollapsed: false,
  searchIsOpened: false,
  bagIsOpened: false,
  accountIsOpened: false,
  burgerIsOpened: false,
  notificationIsOpened: false,
  lockedMessagesPanelIsOpened: false,
  popupLoginPanelIsOpened: false,
  isLoadingPage: false,
  paymentProcessEnded: true,
  promoCodes: [], // Use extra codes during sale
  isProductInFavorites: _isProductInFavorites,
  bagItemsTotalAmount: _getAllBagItemsTotalAmount,
  bagItemsNum: _getAllBagItemsNum,
  getCustomerType: _getCustomerType,
  getCurrentCustomer: _getCurrentCustomer,
  userHasAcceptedCookies: _userHasAcceptedCookies,
  userSessionHasExpired: _userSessionHasExpired,
  userIsValid: _userIsValid,
  getBag,
  getFavorites,
  getRecentlyViewed,
  getCustomer,
  getGuestCustomer,
  dispatchToken: Dispatcher.register((payload) => {
    const actionType = payload.type
    const item = payload.item
    let customer, favorites, bag, customerType, newCustomer, discount, productsStr
    switch (actionType) {
    case AppConstants.APP_START:
      if (!Store.userHasAcceptedCookies()) setTimeout(AppActions.openCookiesPanel, 0)
      _checkURLParams()
      Store.isPrivateSale = item.isPrivateSale
      Store.emit(actionType, item)
      break
    case AppConstants.RELOAD_USER_FROM_SERVER:
      _getUpdatedCustomerFromServer(item, actionType, item)
      break
    case AppConstants.DEFINE_CLIENT:
      customer = Store.getCustomer()
      if (customer.id) {
        if (_userSessionHasExpired(customer)) {
          setTimeout(AppActions.logout, 0, true)
          Store.emit(actionType, item)
        } else {
          _getUpdatedCustomerFromServer(customer.id, actionType, item)
        }
      } else {
        Store.emit(actionType, item)
      }
      break
    case AppConstants.UPDATE_PATH:
      Store.route.old = Store.route.new
      Store.route.new = item
      closeAllPanels()
      setTimeout(AppActions.startLoadingPage)
      Store.emit(actionType, item)
      break
    case AppConstants.CLOSE_ALL_PANELS:
      closeAllPanels()
      Store.emit(actionType)
      break
    case AppConstants.EXPAND_HEADER:
      Store.headerIsCollapsed = false
      Store.emit(actionType)
      break
    case AppConstants.COLLAPSE_HEADER:
      if (Store.searchIsOpened) return
      Store.headerIsCollapsed = true
      Store.emit(actionType)
      break
    case AppConstants.OPEN_SEARCH:
      Store.searchIsOpened = true
      Store.emit(actionType)
      break
    case AppConstants.CLOSE_SEARCH:
      Store.searchIsOpened = false
      Store.emit(actionType)
      break
    case AppConstants.OPEN_BAG:
      Store.bagIsOpened = true
      Store.emit(actionType)
      break
    case AppConstants.CLOSE_BAG:
      Store.bagIsOpened = false
      Store.emit(actionType)
      break
    case AppConstants.OPEN_BURGER:
      Store.burgerIsOpened = true
      if (Store.bagIsOpened) setTimeout(AppActions.closeBag)
      Store.emit(actionType)
      break
    case AppConstants.CLOSE_BURGER:
      Store.burgerIsOpened = false
      Store.emit(actionType)
      break
    case AppConstants.OPEN_ACCOUNT:
      Store.accountIsOpened = true
      Store.emit(actionType)
      break
    case AppConstants.CLOSE_ACCOUNT:
      Store.accountIsOpened = false
      Store.emit(actionType)
      break
    case AppConstants.OPEN_NOTIFICATION:
      Store.notificationIsOpened = true
      Store.emit(actionType, item)
      break
    case AppConstants.CLOSE_NOTIFICATION:
      Store.notificationIsOpened = false
      Store.emit(actionType)
      break
    case AppConstants.OPEN_LOCKED_MESSAGES_PANEL:
      Store.lockedMessagesPanelIsOpened = true
      Store.emit(actionType, item)
      break
    case AppConstants.CLOSE_LOCKED_MESSAGES_PANEL:
      Store.lockedMessagesPanelIsOpened = false
      Store.emit(actionType)
      break
    case AppConstants.OPEN_POPUP_LOGIN_PANEL:
      Store.popupLoginPanelIsOpened = true
      Store.emit(actionType, item)
      break
    case AppConstants.CLOSE_POPUP_LOGIN_PANEL:
      Store.popupLoginPanelIsOpened = false
      Store.emit(actionType)
      break
    case AppConstants.CLOSE_COOKIES_PANEL:
      localSet(AppConstants.COOKIES, { user_informed: true})
      Store.emit(actionType)
      break
    case AppConstants.START_LOADING_PAGE:
      if (Store.route.old.path !== Store.route.new.path) {
        Store.isLoadingPage = true
        NProgress.inc()
      }
      Store.emit(actionType)
      break
    case AppConstants.FINISH_LOADING_PAGE:
      Store.isLoadingPage = false
      NProgress.done(true)
      Store.emit(actionType)
      break
    case AppConstants.START_PAYMENT_PROCESS:
      Store.paymentProcessEnded = false
      customer = Store.getCurrentCustomer()
      bag = Store.getBag()
      productsStr = JSON.stringify(bag.map(d => ({ product: d.product.sku, size: d.size.id, sale: d.product.sale && d.product.sale.discount ? d.product.sale.discount : 'unset' })))
      Analytics.send('pay', 'begin_process', { email: customer.email, type: customer.type, products: productsStr })
      setTimeout(AppActions.openLockedMessagesPanel, 0, preparingOrderMessage())
      Store.emit(actionType)
      break
    case AppConstants.FINISH_PAYMENT_PROCESS:
      customer = Store.getCurrentCustomer()
      bag = Store.getBag()
      discount = Store.promoCodes.length > 0 ? { codes: Store.promoCodes.map(item => { return item.code }).join(',') } : {}
      productsStr = JSON.stringify(bag.map(d => ({ product: d.product.sku, size: d.size.id, sale: d.product.sale && d.product.sale.discount ? d.product.sale.discount : 'unset' })))
      if (item.status === 'payment_require_action' ) {
        Analytics.send('pay', 'require_action', { email: customer.email, type: customer.type, products: productsStr })
        setTimeout(AppActions.stripeHandleCardAction, 0, item.data)
      } else if (item.status === 'payment_not_succeded' ) {
        Analytics.send('pay', 'not_succeded', { message: item.data.message, email: customer.email, type: customer.type, products: productsStr })
        Analytics.gEcom('exception', { description: item.data.message })
        setTimeout(AppActions.openNotification, 0, { type: ERROR, message: item.data.message, stayOpen: false, time: 8000 })
      } else if (item.status === 'succeeded' ) {
        Store.promoCodes = []
        Analytics.send('pay', 'success', { email: customer.email, type: customer.type, products: productsStr })
        Analytics.gEcom('purchase', { transaction_id: item.data.payment.transaction_id, value: parseFloat(Store.bagItemsTotalAmount().totalWithSale, 10), shipping: 0, currency: AppConstants.CURRENCY, items: getBagItems().map(item => formatProductForGA(item.product, item.size)) })
        localSet(AppConstants.BAG, [])
        setTimeout(navigate, 0, '/?notif=payment_success')
      } else if (item.status === 'catch_error' ) {
        Analytics.send('pay', 'failed_catch_error', { email: customer.email, type: customer.type, products: productsStr })
        Analytics.gEcom('exception', { description: 'payment failed: catch error' })
        Store.promoCodes = []
        setTimeout(navigate, 0, '/bag?notif=payment_failed')
      } else if (item.status === 'cancelled' ) {
        Analytics.send('pay', 'cancelled', { email: customer.email, type: customer.type, products: productsStr })
        Analytics.gEcom('exception', { description: 'payment cancelled: cancel' })
      } else {
        Analytics.send('pay', 'failed', { email: customer.email, type: customer.type, products: productsStr })
        Analytics.gEcom('exception', { description: 'payment failed: unknown reason' })
        Store.promoCodes = []
        setTimeout(navigate, 0, '/bag?notif=payment_failed')
      }
      Store.paymentProcessEnded = true
      setTimeout(AppActions.closeLockedMessagesPanel)
      Store.emit(actionType)
      break
    case AppConstants.PAY:
      if (Store.paymentProcessEnded === false) return false
      customer = Store.getCurrentCustomer()
      bag = Store.getBag()
      discount = Store.promoCodes.length > 0 ? { codes: Store.promoCodes.map(item => { return item.code }).join(',') } : {}
      setTimeout(AppActions.startPaymentProcess, 0)
      fetch(`${AppConstants.API_URL}order`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify({ customer, discount, items: bag }) }))
      .then((res) => res.json())
      .then((data) => {
        if (data && data.code === 'payment_require_action') {
          setTimeout(AppActions.finishPaymentProcess, 0, data.code, data)
        } else if (data && data.code === 'payment_not_succeded') {
          setTimeout(AppActions.finishPaymentProcess, 0, data.code, data)
        } else if (data.payment && data.payment.status === 'succeeded') {
          setTimeout(AppActions.finishPaymentProcess, 0, data.payment.status, data)
        } else {
          setTimeout(AppActions.finishPaymentProcess, 0, 'failed')
        }
        Store.emit(actionType)
      })
      .catch(() => {
        setTimeout(AppActions.finishPaymentProcess, 0, 'catch_error')
        Store.emit(actionType)
      })
      break
    case AppConstants.ADD_TO_BAG:
      Analytics.send('bag', 'add', { value: item.product.price, sku: item.product.sku, currency: AppConstants.CURRENCY, title: item.product.title, size: item.size ? item.size.name : 'unset', type: 'shoe' })
      Analytics.gEcom('add_to_cart', { value: parseInt(item.product.price), currency: AppConstants.CURRENCY, items: [formatProductForGA(item.product, item.size)] })
      pushNewItemInBag(item)
      Store.emit(actionType)
      break
    case AppConstants.REMOVE_FROM_BAG:
      Analytics.send('bag', 'remove', { value: item.product.price, currency: AppConstants.CURRENCY, sku: item.product.sku, title: item.product.title, size: item.size ? item.size.name : 'unset', type: 'shoe' })
      Analytics.gEcom('remove_from_cart', { value: parseInt(item.product.price), currency: AppConstants.CURRENCY, items: [formatProductForGA(item.product, item.size)] })
      removeFromBag(item)
      Store.emit(actionType)
      break
    case AppConstants.UPDATE_BAG_PRODUCT_SIZE:
      updateBackProductSize(item)
      Store.emit(actionType)
      break
    case AppConstants.SUBSCRIBE_TO_WAITLIST:
      Store.emit(actionType, { state: 'loading' })
      Analytics.send('waitlist', 'subscribe', { email: item.email, size: item.size, product: { title: item.title, sku: item.sku, style_id: item.style_id } })
      fetch(`${AppConstants.API_URL}waitlist`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify(item) }))
      .then((res) => res.json())
      .then((data) => {
        const error = data.type && data.type === 'error' ? true : false
        if (error) {
          setTimeout(AppActions.openNotification, 0, { type: WARNING, message: data.message })
        }
        else {
          setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'Congratulations! You have been added to the waitlist', time: 4000 })
        }
        Store.emit(actionType)
      })
      .catch(() => {
        setTimeout(AppActions.openNotification, 0, { type: WARNING, message: 'Something went wrong, please try again' })
        Store.emit(actionType)
      })
      break
    case AppConstants.ADD_TO_FAVORITES:
      Analytics.send('favorites', 'add', { value: item.product.price, currency: AppConstants.CURRENCY, sku: item.product.sku, title: item.product.title, type: 'shoe' })
      Analytics.gEcom('add_to_wishlist', { value: parseInt(item.product.price), currency: AppConstants.CURRENCY, items: [formatProductForGA(item.product)] })
      favorites = getFavorites()
      if (favorites.length < 1) {
        pushNewFavorite(item)
        setTimeout(AppActions.openNotification, 0, { type: HEART, message: `Added to your favorites list` })
      } else if (findIndex(favorites, [ 'product.id', item.product.id ]) === -1) {
        pushNewFavorite(item)
        setTimeout(AppActions.openNotification, 0, { type: HEART, message: `Added to your favorites list` })
      } else setTimeout(AppActions.openNotification, 0, { type: HEART, message: `Already added to your favorites list` })
      Store.emit(actionType, localGet(AppConstants.FAVORITES))
      break
    case AppConstants.REMOVE_FROM_FAVORITES:
      Analytics.send('favorites', 'remove', { sku: item.product.sku, title: item.product.title, type: 'shoe' })
      removeFromFavorites(item)
      Store.emit(actionType)
      break
    case AppConstants.MOVE_FAVORITES_TO_BAG:
      Analytics.send('bag', 'move_from_favorites', { value: item.product.price, currency: AppConstants.CURRENCY, sku: item.product.sku, title: item.product.title, type: 'shoe' })
      Analytics.gEcom('add_to_cart', { value: parseInt(item.product.price), currency: AppConstants.CURRENCY, items: [formatProductForGA(item.product)] })
      pushNewItemInBag(item)
      removeFromFavorites(item)
      setTimeout(AppActions.openNotification, 0, { type: BAG, message: 'Item moved to bag' })
      if (Store.accountIsOpened) setTimeout(AppActions.closeAccount)
      Store.emit(actionType)
      break
    case AppConstants.ADD_TO_RECENTLY_VIEWED:
      const recentlyViewed = getRecentlyViewed()
      if (findIndex(recentlyViewed, [ 'product.id', item.product.id ]) === -1 && item.product.availability_status !== 'hidden') { // test if already in cache
        const newArray = [item, ...recentlyViewed].slice(0, 8)
        localSet(AppConstants.RECENTLY_VIEWED, newArray)
      }
      Store.emit(actionType, localGet(AppConstants.RECENTLY_VIEWED))
      break
    case AppConstants.REGISTER:
      setTimeout(AppActions.openNotification, 0, { type: LOADING, message: 'We are processing your request...', stayOpen: true })
      _registerUser(item)
      .then((data) => {
        const customerIsRegistered = data.id ? true : false
        if (customerIsRegistered) {
          _emitUpdateCustomerData(actionType, data, { state: 'success' })
          Analytics.gEcom('sign_up', { method: 'email' })
          setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'Account created successfully' })
        } else {
          _emitUpdateCustomerData(actionType, {}, { state: 'error', ...data })
          Analytics.gEcom('exception', { description: data.message })
          setTimeout(AppActions.openNotification, 0, { type: WARNING, message: data.message })
        }
      })
      .catch(() => _emitUpdateCustomerData(actionType, {}, { state: 'error' }))
      break
    case AppConstants.LOGIN:
      localSet(AppConstants.GUEST_CUSTOMER, {})
      fetch(`${AppConstants.API_URL}signin`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify(item) }))
      .then((res) => res.json())
      .then((data) => {
        const error = data.type && data.type === 'error' ? true : false
        if (error) {
          Analytics.gEcom('exception', { description: data.message })
          setTimeout(AppActions.openNotification, 0, { type: WARNING, message: data.message })
        }
        else {
          Analytics.gEcom('login', { method: 'email' })
          setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'You logged in successfully' })
        }
        _emitUpdateCustomerData(actionType, data)
      })
      .catch(() => _emitUpdateCustomerData(actionType, {}))
      break
    case AppConstants.APPLY_PROMO_CODE:
      Analytics.send('checkout', 'apply_code', item)
      fetch(`${AppConstants.API_URL}coupon-info`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify({code: item.code, total_amount: item.total_amount}) }))
      .then((res) => res.json())
      .then((data) => {
        const error = !data.validation.is_valid
        if (error) {
          setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'Promo code not valid anymore' })
          Store.emit(actionType, item)
        } else {
          const promoItem = {
            code: item.code,
            ...data
          }
          Store.promoCodes = [ promoItem, ...Store.promoCodes]
          setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'Promo code have been applied successfully' })
          Store.emit(actionType, promoItem)
        }
      })
      .catch(() => {
        setTimeout(AppActions.openNotification, 0, { type: WARNING, message: 'Something went wrong, please try again' })
        Store.emit(actionType)
      })
      break
    case AppConstants.UPDATE_CUSTOMER:
      fetch(`${AppConstants.API_URL}update-customer`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify(item) }))
      .then((res) => res.json())
      .then((data) => {
        const error = data.type && data.type === 'error' ? true : false
        if (error) {
          setTimeout(AppActions.openNotification, 0, { type: WARNING, message: data.message })
          Store.emit(actionType, { state: 'error', ...data })
        } else {
          setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'Your changes have been saved' })
          _emitUpdateCustomerData(actionType, data, {state: 'success', ...data})
        }
      })
      .catch(() => {
        setTimeout(AppActions.openNotification, 0, { type: WARNING, message: 'Something went wrong, please try again' })
      })
      break
    case AppConstants.LOGOUT:
      customer = Store.getCustomer()
      let silenced = item
      if (!customer.id) _emitUpdateCustomerData(actionType, {}, { state: 'error'})
      fetch(`${AppConstants.API_URL}logout?id=${customer.id}`, AppConstants.GET_OPTIONS)
      .then((res) => res.json())
      .then(() => {
        if (!silenced) setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: 'You logged out successfully. See you soon' })
        _emitUpdateCustomerData(actionType, {}, { state: 'success'})
      })
      .catch(() => {
        if (!silenced) setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'Something went wrong, please try again' })
        _emitUpdateCustomerData(actionType, {}, { state: 'error'})
      })
      break
    case AppConstants.SEND_CONTACT_FORM_REQUEST:
      setTimeout(AppActions.openNotification, 0, { type: LOADING, message: 'We are processing your request...', stayOpen: true })
      fetch(`${AppConstants.API_URL}contact-request`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify(item) }))
      .then((res) => res.json())
      .then((data) => {
        if (data.type === 'error') setTimeout(AppActions.openNotification, 0, { type: WARNING, message: data.message })
        else setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: data.message })
      })
      .catch(() => {
        setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'Something went wrong, please try again' })
      })
      break
    case AppConstants.SUBSCRIBE_TO_NEWSLETTER:
      let showNotification = item.silenced ? false : true
      if (showNotification) setTimeout(AppActions.openNotification, 0, { type: LOADING, message: 'We are processing your request...', stayOpen: true })
      fetch(`${AppConstants.API_URL}subscribe-to-newsletter`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify(item) }))
      .then((res) => res.json())
      .then((data) => {
        if (showNotification) {
          if (data.type === 'error') setTimeout(AppActions.openNotification, 0, { type: WARNING, message: data.message })
          else setTimeout(AppActions.openNotification, 0, { type: SUCCESS, message: data.message })
        }
      })
      .catch(() => {
        if (showNotification) setTimeout(AppActions.openNotification, 0, { type: ERROR, message: 'Something went wrong with your email, please try again' })
      })
      Store.emit(actionType)
      break
    case AppConstants.SET_CUSTOMER:
      if (item.type === AppConstants.GUEST_CUSTOMER) {
        localSet(AppConstants.CUSTOMER, {})
        localSet(AppConstants.GUEST_CUSTOMER, item)
      } else {
        localSet(AppConstants.GUEST_CUSTOMER, {})
        localSet(AppConstants.CUSTOMER, item)
      }
      Store.emit(actionType, item)
      break
    case AppConstants.SET_STRIPE_TOKEN_TO_CUSTOMER:
      customer = Store.getCurrentCustomer()
      newCustomer = {
        ...customer,
        stripe: { payment_method_id: item }
      }
      localSet(customer.type, newCustomer)
      Store.emit(actionType, item)
      break
    case AppConstants.SET_STRIPE_PAYMENT_INTENT:
      customer = Store.getCurrentCustomer()
      newCustomer = {
        ...customer,
        stripe: { payment_intent_id: item.intent, order_id: item.order }
      }
      localSet(customer.type, newCustomer)
      Store.emit(actionType, item)
      break
    case AppConstants.RESET_CUSTOMER:
      if (item.type === AppConstants.GUEST_CUSTOMER || item.type === AppConstants.CUSTOMER) {
        if (!item.keys || item.keys.length === 0) localSet(item.type, {})
        else {
          customer = item.type === AppConstants.GUEST_CUSTOMER ? Store.getGuestCustomer() : Store.getCustomer()
          let newCustomer = assign({}, customer)
          item.keys.forEach(key => delete newCustomer[key])
          localSet(item.type, newCustomer)
        }
      }
      Store.emit(actionType)
      break
    default:
      Store.emit(actionType, item)
      break
    }
  })
})

export default Store
