import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Link from 'Components/Utils/Link'
import { customerAccountLinks } from 'Components/Utils/helpers'
import styles from './index.module.scss'
import ProductItem from 'Components/UI/ProductItem'
import WindowStore from 'WindowStore'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import transform from 'dom-css-transform'
import Form from 'Components/UI/Form'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import Input from 'Components/UI/Input'
import MainBtn from 'Components/UI/MainBtn'
import SimpleTextIconButton from 'Components/UI/SimpleTextIconButton'

class AccountPanel extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.mailInput = React.createRef()
    this.passwordInput = React.createRef()
    this.state = {
      openedOnce: false,
      isOpen: false,
      products: [],
      customer: {}
    }
  }
  onSubmit = () => {
    const user = {
      email: this.mailInput.current.state.value,
      password: this.passwordInput.current.state.value,
    }
    setTimeout(AppActions.login, 0, user)
  }
  componentDidMount() {
    AppStore.on(AppConstants.OPEN_ACCOUNT, this.openAccount)
    AppStore.on(AppConstants.CLOSE_ACCOUNT, this.closeAccount)
    AppStore.on(AppConstants.ADD_TO_FAVORITES, this.updateCurrentState)
    AppStore.on(AppConstants.REMOVE_FROM_FAVORITES, this.updateCurrentState)
    AppStore.on(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentState)
    AppStore.on(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.on(AppConstants.LOGOUT, this.onLogoutCustomer)
    window.addEventListener('resize', this.resize)
    this.updateCurrentState()
  }
  componentDidUpdate() {
    if (!this.state.isOpen) this.parentTimeout = setTimeout(() => { if (this.parent.current) transform(this.parent.current, `translateY(${-999999}px)`) }, 400)
    else transform(this.parent.current, `translateY(${0}px)`)
  }
  componentWillUnmount() {
    clearTimeout(this.parentTimeout)
    AppStore.off(AppConstants.OPEN_ACCOUNT, this.openAccount)
    AppStore.off(AppConstants.CLOSE_ACCOUNT, this.closeAccount)
    AppStore.off(AppConstants.ADD_TO_FAVORITES, this.updateCurrentState)
    AppStore.off(AppConstants.REMOVE_FROM_FAVORITES, this.updateCurrentState)
    AppStore.off(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentState)
    AppStore.off(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.off(AppConstants.LOGOUT, this.onLogoutCustomer)
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    if (this.productItemRefs.lenght > 0) this.productItemRefs.forEach(product => product.current.resize())
  }
  onLoginCustomer = () => {
    const customer = AppStore.getCustomer()
    this.setState({ customer })
  }
  onLogoutCustomer = () => {
    this.setState({ customer: {} })
  }
  updateCurrentState = () => {
    const products = []
    const currentProducts = AppStore.getFavorites()
    const customer = AppStore.getCustomer()
    currentProducts.forEach(item => products.push(item.product))
    this.setState({ products, customer }, this.resize)
  }
  openAccount = () => {
    this.setState({isOpen: true, openedOnce: true})
  }
  closeAccount = () => {
    this.setState({isOpen: false})
  }
  onFavoriteToBagClick = (item) => {
    setTimeout(AppActions.moveFavoritesToBag, 0, { product: item })
  }
  onProductItemRemoveClick = (product) => {
    setTimeout(AppActions.removeFromFavorites, 0, product)
  }
  render() {
    const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
    this.productItemRefs = []
    const viewType = WindowStore.getViewType()
    const customerIsLogged = this.state.customer.id ? true : false
    const openClass = this.state.isOpen ? styles.open : ''
    const products = this.state.products.map((product, index) => {
      const productRef = React.createRef()
      if (this.state.openedOnce) this.productItemRefs.push(productRef)
      return (
        <div key={`account-product-item---${product.id}-${index}`} className='u-col-6 u-col-lg-3'>
          <ProductItem
            baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
            data={{product}}
            index={0}
            ref={productRef}
            className=''
            extraClasses={'u-down-scale--1'}
            showFavoriteIcon={false}
            showRemoveIcon={true}
            showOtherColors={false}
            showInfo={false}
            lockSale={!isCustomer}
            buttonText={'Add to bag'}
            pageToGo={`/${product.permalink}`}
            buttonOnClick={this.onFavoriteToBagClick}
            removeOnClick={this.onProductItemRemoveClick}
            miniVersion={true}
          />
        </div>
      )
    })
    let favoritesMessage
    if (this.state.products.length === 0) favoritesMessage = (
      <p>
        Click the icon <span>
          <svg className={`${styles.heartIcon} t-fill-color-white`}>
            <use xlinkHref='#heart-icon' />
          </svg>
        </span> on any product to save it to your Favourites
      </p>
    )
    else favoritesMessage = <MainBtn url={`${customerIsLogged ? '/my-account/favorites' : '/favorites'}`} color={`white`} text={`View Favorites`} />
    const uiLogged = (
      <div className='u-col-12'>
        <span className={`t-color-white t-title-2 u-uppercase`}>Hello, {this.state.customer.display_name}</span>
        <div className='u-height--40'></div>
        <ul className={`${styles.accountListContainer} u-col-12`}>
          {
            customerAccountLinks.map((link, i) => (
              <li key={`customer-account-btn-${i}`} className={`u-inline-block u-pad-y--small`}>
                <Link className={`${styles.customerLinks} t-paragraph-1 u-color-to-white`} to={link.to}>{link.title}</Link>
              </li>
            ))
          }
        </ul>
        <div className='u-height--40'></div>
        <div className='u-row'>
          <BackgroundBtn
            className='u-pad-x'
            text='Go to my account'
            onClick={() => { navigate('/my-account/') }}
            highlighted
          />
          <div className='u-col-lg-12'>
            <MainBtn
              color='white'
              text='log out'
              onClick={() => { setTimeout(AppActions.logout) }}
              url=''
              className={`${styles.signButton} u-absolute u-pos-r--0 u-float-r`}
            />
          </div>
        </div>
      </div>
    )
    const uiNotLogged = (
      <Form submit={this.onSubmit}>
        <Input
          ref={this.mailInput}
          id='mail_address'
          type='mail'
          placeholder='Your email address'
          required
          negative
        />
        <Input
          ref={this.passwordInput}
          id='password'
          type='password'
          placeholder='Your password'
          required
          negative
        />
        <div className='u-row u-pad-t--spacing--small'>
          <BackgroundBtn
            className='u-col-6 u-col-lg-4'
            text='Login'
            type='submit'
            highlighted
          />
          <div className='u-col-lg-8'>
            <MainBtn
              color='gray'
              text='create account'
              url='/my-account/register'
              className={`${styles.signButton} u-absolute u-pos-r--0 u-float-r`}
            />
          </div>
        </div>
      </Form>
    )
    const favoritesCol = (
      <div className='u-col-lg-6'>
        <div className='u-fit'>
          {this.state.products.length === 0 &&
            <div className={`${styles.checkoutButton} u-col-12`}>
              <MainBtn
                color='white'
                text='continue shopping'
                onClick={() => { setTimeout(AppActions.closeAccount) }}
              />
            </div>
          }
          { this.state.openedOnce && <div className='u-row'>{products}</div>}
          <div className={`u-col-lg-10 u-offset-lg-1 u-height--${this.state.products.length === 0 ? 60 : 120}`} />
        </div>
      </div>
    )
    const accountCol = (
      <div className='u-col-lg-4 u-offset-lg-1'>
        { !customerIsLogged &&
          <div className={`u-pad-l--40`}>
            <p className={`t-color-gray t-title-2 u-uppercase`}>Login</p>
            {uiNotLogged}
            <p className={`t-paragraph-0 t-color-gray u-pad-t--10`}>
              <Link to={`/forgot-password`} className={`${styles.passwordButton}`}>Forgot your password?</Link>
            </p>
          </div>
        }
        { customerIsLogged && uiLogged }
      </div>
    )
    const mainRow = <div className='u-row'>{favoritesCol}{accountCol}</div>
    return (
      <div ref={this.parent} className={`${styles.parent} ${openClass} account-panel u-fixed u-fit u-z-index--9981 u-overflow-y-s`}>
        <div className={`${styles.container} u-relative u-fit-w u-pos-tl u-z-index--2`}>
          <div className='u-fit u-absolute'>
            <div className='u-flex u-fit u-col-lg-1 u-offset-lg-7'><div className={`${styles.separator}`}></div></div>
          </div>
          <div className='u-text-center u-fit'>
            <div className='u-flex u-col-12 u-height--50'></div>
            <div className='u-row'>
              <div className='u-flex u-col-lg-9 u-offset-lg-1'>
                <div className='u-d-lg-block t-color-white u-uppercase t-title-1'>{favoritesMessage}</div>
              </div>
              <div className='u-col-12 u-col-lg-1'>
                <SimpleTextIconButton className={`${styles.closeBtn} u-float-r`} text='Close' svgId='cross-icon' negative onClick={() => { setTimeout(AppActions.closeAccount) }} />
              </div>
            </div>
            <div className='u-col-lg-10 u-offset-lg-1 u-height--50'/>
          </div>
          <div className='u-offset-lg-1 u-col-lg-11'>{mainRow}</div>
          <div className={`${this.state.products.length <= 4 ? 'u-height--60' : ''}`}/>
        </div>
        <div onClick={() => { setTimeout(AppActions.closeAccount) }} className={`${styles.background} u-absolute u-fit-vwh u-z-index--1 u-pos-tl`}></div>
      </div>
    )
  }
}

export default AccountPanel
