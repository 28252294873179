import React from 'react'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'

export const NORMAL = styles.normalState
export const HIGHLIGHTED = styles.highlightedState
export const ACTIVE = styles.activeState

const MainBtn = (props) => {
  const url = props.url ? props.url : ''
  const noLink = props.onClick === undefined && props.url === undefined
  const onClick = (e) => {
    if (props.onClick === undefined) return
    e.preventDefault()
    props.onClick()
  }
  return (
    <div className={`t-title-1 ${styles.button} ${props.defaultState} ${props.className} u-inline-block u-user-select--none`}>
      { (url && !noLink) && <Link to={url} onClick={onClick} className={`${styles.link} t-color-${props.color}`} dangerouslySetInnerHTML={{__html: props.text}}></Link> }
      { (!url && !noLink) && <Link onClick={onClick} className={`${styles.link} t-color-${props.color} u-btn`} dangerouslySetInnerHTML={{__html: props.text}}></Link> }
      { noLink &&
      <div className={`t-color-${props.color} u-btn`} dangerouslySetInnerHTML={{__html: props.text}}></div>
      }
      <div className={`${styles.hover} u-fit-w t-bg-${props.color}`} style={{marginTop: props.lineMargin}}></div>
    </div>
  )
}

MainBtn.defaultProps = {
  color: 'black',
  lineMargin: 6,
  defaultState: HIGHLIGHTED
}

export default MainBtn
