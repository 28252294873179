import AppStore from 'AppStore'
import find from 'lodash/find'
import AppConstants from 'AppConstants'

export const shippingIsSameAsBilling = (customer) => {
  let isSame = true
    const {billing, shipping} = customer
    for (const key in shipping) {
      const shippingField = shipping[key]
      const billingField = billing[key]
      if (billingField !== shippingField && key !== 'email' && key !== 'phone') {
        isSame = false
        return isSame
      }
    }
    return isSame
}

export const getFormData = (customer, form, type) => {
  return {
    first_name: form[`${type}@first_name`].state.value,
    last_name: form[`${type}@last_name`].state.value,
    address_1: form[`${type}@address_1`].state.value,
    address_2: form[`${type}@address_2`].state.value,
    company: form[`${type}@company`].state.value,
    state: form[`${type}@state`].state.value,
    country: form[`${type}@country`].getValue().value,
    city: form[`${type}@city`].state.value,
    postcode: form[`${type}@postcode`].state.value,
    phone: (form[`${type}@phone`] && form[`${type}@phone`].state) ? form[`${type}@phone`].state.value : customer.shipping.phone
  }
}

export const validateAddressForm = (data) => {
  if (!data) return false
  if (!data.first_name || data.first_name.length <= 2) return false
  if (!data.last_name || data.last_name.length <= 2) return false
  if (!data.address_1 || data.address_1.length <= 2) return false
  if (!data.country || data.country.length <= 1) return false
  if (!data.city || data.city.length <= 2) return false
  return true
}

export const removeObjectDuplicatesFromArray = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

export const cancelablePromise = (promise) => {
  let hasCanceled_ = false
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
      error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
    )
  })
  return {
    promise: wrappedPromise,
    cancel: () => {
      hasCanceled_ = true
    }
  }
}

export const valBetween = (v, min, max) => {
  return (Math.min(max, Math.max(min, v)))
}

export const getBagItems = () => {
  const bag = AppStore.getBag()
  return bag && Array.isArray(bag) ? bag : []
}

export const imagePlaceholder = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACpCAYAAACRdwCqAAAAAXNSR0IArs4c6QAABPhJREFUeAHt1IEJgFAMQ0ErTtep3cwPbvHgOkG4lMzufpcjQIBAQOAOZBSRAAECv4DB8ggECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVA4JmZFwMBAgQKAgfVfwP0z0zdDgAAAABJRU5ErkJggg==`

export const customerAccountLinks = [
  { title: 'Order History', to: '/my-account/view-order' },
  { title: 'Account Details', to: '/my-account/details' },
  { title: 'Addresses', to: '/my-account/addresses' },
  { title: 'Newsletters', to: '/my-account/newsletters' },
  { title: 'Favorites', to: '/my-account/favorites' }
]

export const productSizes = [
  { id: '350', name: '35' },
  { id: '355', name: '35.5' },
  { id: '360', name: '36' },
  { id: '365', name: '36.5' },
  { id: '370', name: '37' },
  { id: '375', name: '37.5' },
  { id: '380', name: '38' },
  { id: '385', name: '38.5' },
  { id: '390', name: '39' },
  { id: '395', name: '39.5' },
  { id: '400', name: '40' },
  { id: '405', name: '40.5' },
  { id: '410', name: '41' }
]

export const productSides = [
  { name: 'Side view'},
  { name: 'Angle view'},
  { name: 'View from above'},
  { name: 'View from back'},
  { name: 'First detail'},
  { name: 'Second detail'},
  { name: 'Third detail'},
  { name: 'Fourth detail'},
  { name: 'Fifth detail'},
  { name: 'Sixth detail'},
  { name: 'Seventh detail'}
]

export const formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [day, month, year].join('-')
}

export const formatProductForGA = (product, size) => {
  const s = size && size.id ? size.id : 'unset'
  const brand = find(product.taxonomy, { slug: 'collections' }).subcategories[0].name
  const category = find(product.taxonomy, { slug: 'categories' }).subcategories.map(item => item.name).join(',')
  return {
    id: `${product.sku}`,
    name: product.title,
    brand,
    category,
    variant: s,
    quantity: 1,
    price: product.price.toString()
  }
}

export const getAvailabilityTextByID = (id) => {
  switch (id) {
    case 'available' : return 'Available'
    case 'unavailable' : return 'Sold Out'
    case 'in_store' : return 'Available in Store'
    case 'sold_out' : return 'Sold Out'
    case 'hidden' : return 'Sold Out'
    case 'coming_soon' : return 'Coming soon'
    default : return 'Coming soon'
  }
}

export const getAvailabilityColorByID = (id) => {
  switch (id) {
    case 'available' : return AppConstants.COLOR_SUCCESS
    case 'unavailable' : return AppConstants.COLOR_ERROR
    case 'in_store' : return AppConstants.COLOR_BLUE
    case 'sold_out' : return AppConstants.COLOR_ERROR
    case 'hidden' : return AppConstants.COLOR_ERROR
    case 'coming_soon' : return AppConstants.COLOR_WARNING
    default : return AppConstants.COLOR_WARNING
  }
}
