import React, { Component } from 'react'
import resizePositionProportionally from 'resize-position-proportionally'
import styles from './index.module.scss'
import WindowStore from 'WindowStore'
import AppConstants from 'AppConstants'
import CircledIcon from 'Components/UI/CircledIcon'
import Img from 'Components/UI/Img'

class Video extends Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
    this.parent = React.createRef()
    this.source = React.createRef()
    this.preview = React.createRef()
    this.LOADED = false
    this.state = {
      soundIsMuted: true,
      showPreview: this.props.preview ? true : false
    }
  }
  componentDidMount() {
    this.reset()
  }
  componentDidUpdate() {
    this.reset()
  }
  reset = () => {
    this.LOADED = false
    this.player = this.video.current
    this.player.removeEventListener('canplay', this.onCanPlay)
    this.player.removeEventListener('canplaythrough', this.onCanPlay)
    if (this.props.autoplay) {
      this.player.setAttribute('autoplay', 'true')
      this.player.setAttribute('muted', 'true')
    } else {
      this.player.removeAttribute('autoplay')
      this.player.removeAttribute('muted')
    }
    if (this.props.muted) this.player.setAttribute('muted', 'true')
    else this.player.removeAttribute('muted')
    this.player.addEventListener('canplay', this.onCanPlay)
    this.player.addEventListener('canplaythrough', this.onCanPlay)
  }
  onCanPlay = () => {
    if (this.LOADED) return
    this.LOADED = true
    if (this.props.didLoad) this.props.didLoad(this.parent.current, this.video.current)
    if (this.props.full) this.setSize()
    if (this.preview.current) this.preview.current.classList.add(styles.previewHide)
    setTimeout(() => {
      this.setState({ showPreview: false })
    }, 400)
  }
  componentWillUnmount() {
    this.player.removeEventListener('canplay', this.onCanPlay)
    this.player.removeEventListener('canplaythrough', this.onCanPlay)
  }
  setSize() {
    const viewType = WindowStore.getViewType()
    const parent = this.parent.current
    const video = this.video.current
    const orientation = this.props.orientation ? this.props.orientation : undefined
    if (parent && parent.parentElement) {
      const vars = resizePositionProportionally(
        parent.parentElement.offsetWidth,
        parent.parentElement.offsetHeight,
        video.offsetWidth,
        video.offsetHeight,
        orientation
      )
      parent.style.position = 'absolute !important'
      parent.style.top = `${vars.top}px`
      parent.style.left = `${vars.left}px`
      parent.style.width = `${vars.width}px`
      parent.style.height = `${vars.height}px`
      if (this.props.scale !== 1) {
        const scale = viewType !== AppConstants.MOBILE ? this.props.scale : 1.01
        parent.style.transform = `scale(${scale})`
      }
    }
  }
  resize() {
    if (this.LOADED && this.props.full) this.setSize()
  }
  play() {
    this.player.play()
  }
  pause() {
    this.player.pause()
  }
  getVideo = (children) => {
    if (!this.state.soundIsMuted || !this.props.muted) return <video preload={''} playsInline muted={false} loop={this.props.loop ? true : false} ref={this.video}>{children}</video>
    if (this.props.autoplay) {
      return <video preload={''} autoPlay muted playsInline loop={this.props.loop ? true : false} ref={this.video}>{children}</video>
    } else {
      return <video preload={''} muted={this.props.muted ? true : false} loop={this.props.loop ? true : false} ref={this.video}>{children}</video>
    }
  }
  onSoundControlClick = () => {
    if (this.state.soundIsMuted) this.setState({ soundIsMuted: false })
    else this.setState({ soundIsMuted: true })
  }
  render() {
    const src = this.props.lazy || !this.props.preload ? '' : this.props.src
    const video = this.getVideo( <source ref={this.source} src={src}/> )
    return (
      <div key={`video-${src}`} ref={this.parent} data-url={`${this.props.src}`} preload={`${this.props.preload}`} lazy={`${this.props.lazy}`} className={`o-video-comp u-fit u-relative u-overflow-hidden ${this.props.className}`}>
        { this.state.showPreview &&
          <div ref={this.preview} className={styles.previewImageWrapper}>
            <Img
              src={ this.props.preview.src }
              srcRetina={ this.props.preview.srcRetina }
              noRetinaOnMobile={true}
              full
              alt={ this.props.preview.description }
            />
          </div>
        }
        { video }
        { this.props.soundControl &&
          <CircledIcon
            svgId={`${this.state.soundIsMuted ? 'sound-off' : 'sound-on'}`}
            bgColor={`black`}
            onClick={this.onSoundControlClick}
            className={`${styles.soundControl} u-btn`}
          />
        }
      </div>
    )
  }
}

Video.defaultProps = {
  soundControl: false,
  autoplay: true,
  muted: true,
  loop: true,
  preload: true,
  lazy: false,
  full: false,
  scale: 1
}

export default Video
