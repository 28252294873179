import React, { Component } from 'react'
import styles from './index.module.scss'
import PropTypes from 'prop-types'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import AppStore from 'AppStore'
import isRetina from 'is-retina'
import { navigate } from 'gatsby'
import resizePositionProportionally from 'resize-position-proportionally'

class Img extends Component {
  constructor(props) {
    super(props)
    this.LOADED = false
    this.parent = React.createRef()
    this.defaultSrc = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6N0NFRjc5NkZBQjgxMTFFODkyMTY5RTRBOEY0REEzNjMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6N0NFRjc5NzBBQjgxMTFFODkyMTY5RTRBOEY0REEzNjMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3Q0VGNzk2REFCODExMUU4OTIxNjlFNEE4RjREQTM2MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3Q0VGNzk2RUFCODExMUU4OTIxNjlFNEE4RjREQTM2MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqmjhtcAAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC`
    this.state = {
      imgSrc: this.defaultSrc
    }
  }
  getSrcByDeviceSize = () => {
    const windowW = WindowStore.Size.w
    let size = this.props.src
    if (isRetina() && this.props.srcRetina && this.props.srcRetina.length > 1) size = this.props.srcRetina
    if (windowW > AppConstants.MQ_XXLARGE) size = this.props.srcRetina
    if (windowW < AppConstants.MQ_MEDIUM && this.props.noRetinaOnMobile) size = this.props.src
    return size
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.imgSrc === this.state.imgSrc) return false
    return true
  }
  componentDidMount() {
    this.parent.current.setAttribute('loaded', false)
    if (this.props.autoload && !this.props.lazy) this.setState({ imgSrc: this.getSrcByDeviceSize() })
  }
  setSize = () => {
    const viewType = WindowStore.getViewType()
    const parent = this.parent.current
    const orientation = this.props.orientation ? this.props.orientation : undefined
    const vars = resizePositionProportionally(
      parent.parentElement.offsetWidth,
      parent.parentElement.offsetHeight,
      parent.offsetWidth,
      parent.offsetHeight,
      orientation
    )
    parent.style.position = 'absolute'
    parent.style.top = `${vars.top}px`
    parent.style.left = `${vars.left}px`
    parent.style.width = `${vars.width}px`
    parent.style.height = `${vars.height}px`
    if (this.props.scale !== 1) {
      const scale = viewType !== AppConstants.MOBILE ? this.props.scale : Math.max(this.props.scale, 0.75)
      parent.style.transform = `scale(${scale})`
    }
  }
  resize = () => {
    if (this.LOADED && this.props.full) this.setSize()
  }
  onImageLoad = () => {
    const currentUrl = this.parent.current.getAttribute('src')
    if (currentUrl !== this.defaultSrc && !this.LOADED) {
      this.LOADED = true
      this.parent.current.setAttribute('loaded', this.LOADED)
      if (this.props.lazy) this.parent.current.classList.add(styles.showParent)
      if (this.props.didLoad) {
        this.props.didLoad({
          width: this.parent.current.width,
          height: this.parent.current.height,
          el: this.parent.current
        })
      }
      if (this.props.full) this.setSize()
      if (this.onlazyLoaded) this.onlazyLoaded()
    }
  }
  lazyload = (cb) => {
    this.onlazyLoaded = cb
    this.setState({ imgSrc: this.getSrcByDeviceSize() })
  }
  onClick = () => {
    if (this.props.pageToGo && AppStore.route.new.path !== this.props.pageToGo) navigate(this.props.pageToGo)
    else {
      if (this.props.onClick) this.props.onClick(this.parent.current)
    }
  }
  render() {
    const lazy = this.props.lazy
    let url = `${this.props.baseUrl}${this.state.imgSrc}`
    if (lazy && !this.LOADED) url = this.defaultSrc
    if (url.indexOf('data:image') > -1) url = this.defaultSrc
    return (
      <img
        data-url={this.getSrcByDeviceSize()}
        lazy={lazy === true ? 'true' : 'false'}
        onClick={this.onClick}
        alt={this.props.alt}
        ref={this.parent}
        onLoad={this.onImageLoad}
        className={`${this.props.className ? this.props.className : ''}`}
        src={url}
        id={this.props.id}
      />
    )
  }
}

Img.defaultProps = {
  baseUrl: '',
  autoload: true,
  full: false,
  scale: 1,
  lazy: false
}

Img.propTypes = {
  src: PropTypes.string.isRequired,
  srcRetina: PropTypes.string.isRequired,
}

export default Img
