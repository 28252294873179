const S3_URL = 'https://cloudfront.stellaluna.co/'

module.exports = {
  siteUrl: 'https://stellaluna.co',
  siteTitle: 'Stella Luna',
  siteTitleAlt: 'Stella Luna Fashion',
  siteDescription: 'Enter the Stella Luna universe and discover a fresh and feminine offering of high-quality flats, heels, booties and boots for the modern urban woman of today.',
  siteKeywords: [ 'stellaluna', 'shoes', 'fashion', 'paris', 'quality', 'woman', 'flats', 'heels', 'boots' ],
  siteAuthor: 'Stellaluna',
  siteCopyright: '© Copyright - Stella Luna',
  siteLocation: 'Paris, FRANCE',
  siteTwitter: '@',
  siteOgImage: `${S3_URL}misc/share_image_1200x630.jpg`,
  siteTwitterImage: `${S3_URL}misc/share_image_500x250.jpg`,
  siteFBAppID: '',
  S3_URL: S3_URL
}
