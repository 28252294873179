import React from 'react'
import Price from 'Components/Utils/FormatPrice'
import AppConstants from 'AppConstants'
import AppStore from 'AppStore'
import { CSSTransition } from 'react-transition-group'
import styles from './index.module.scss'

const SummaryInformation = (props) => {
  const totalAmount = AppStore.bagItemsTotalAmount()
  const color = props.color
  const fontStyle = props.fontStyle
  const includeSale = totalAmount.includeSale
  const beforeSalePrice = Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, totalAmount.total )
  const { promo } = props
  const promoIsApplied = promo && promo.validation && promo.validation.is_valid ? true : false
  const subTotal = Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, props.lockSale ? totalAmount.total : totalAmount.totalWithSale )
  const totalPrice = Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, props.lockSale ? totalAmount.totalWithPromos : totalAmount.totalWithSaleAndPromos )
  return (
    <>
      <div className='u-row'>
        <p className={`t-color-${color} ${fontStyle} u-col-6`}>Subtotal</p>
        <p className={`t-color-${color} ${fontStyle} u-col-6 u-text-right`}>
          { (!includeSale || props.lockSale) && <span>{subTotal}</span>}
          { (includeSale && !props.lockSale) &&
          <span>
            <del>
              <span>{beforeSalePrice}</span>
            </del>
            <span className={styles.salePrice}>{subTotal}</span>
          </span>
          }
        </p>
      </div>
      { promoIsApplied &&
      <CSSTransition
        timeout={400}
        in={true}
        appear={true}
        enter={false}
        exit={false}
        classNames={{
          appear: 'u-fade-appear',
          appearActive: 'u-fade-appear-active--simple'
        }}>
        <div>
          <div className='u-height--10' />
          <div className='u-row'>
            <p className={`t-color-${color} ${fontStyle} t-color-red u-col-6`}>{`Promo Code ${promo.type === 'percent' ? `${promo.discount}%` : ''}`}</p>
            <p className={`t-color-${color} ${fontStyle} t-color-red u-col-6 u-text-right`}>- {Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, totalAmount.totalPromoCodesDiscount )}</p>
          </div>
        </div>
      </CSSTransition>
      }
      { (includeSale && !props.lockSale) &&
      <div>
        <div className='u-height--10' />
        <div className='u-row'>
          <p className={`t-color-${color} ${fontStyle} t-color-red u-col-6`}>Saved</p>
          <p className={`t-color-${color} ${fontStyle} t-color-red u-col-6 u-text-right`}>- {Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, (totalAmount.saved + totalAmount.totalPromoCodesDiscount) )}</p>
        </div>
      </div>
      }
      <div className='u-height--10' />
      <div className='u-row'>
        <p className={`t-color-${color} ${fontStyle} u-col-6`}>Shipping</p>
        <p className={`t-color-${color} ${fontStyle} u-col-6 u-text-right`}>{`${Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, 0 )} (Free shipping)`}</p>
      </div>
      <div className='u-height--lg--20'/>
      <div className='u-height--only-lg--20'/>
      <div className='u-separator t-bg-gray'></div>
      <div className='u-height--lg--20'/>
      <div className='u-height--only-lg--20'/>
      <div className='u-row'>
        <p className={`t-color-${color} ${fontStyle} t-bold u-col-6`}>Total</p>
        <p className={`t-color-${color} ${fontStyle} ${styles.total} t-bold u-col-6 u-text-right`}>
          <span>{totalPrice}</span>
        </p>
      </div>
      <div className='u-height--lg--10'/>
      <div className='u-height--only-lg--10'/>
      <div className='u-row'>
        <p className={`t-color-${color} ${fontStyle} u-col-12 u-text-right u-opacity--40`}><span>VAT included</span></p>
      </div>
    </>
  )
}

SummaryInformation.defaultProps = {
  color: 'black',
  fontStyle: 't-paragraph-0',
  lockSale: true
}

export default SummaryInformation
