import React, { Component } from 'react'
import Link from 'Components/Utils/Link'
import AppConstants from 'AppConstants'
import styles from './index.module.scss'

class Footer extends Component {
  render() {
    const acceptedCards = [ 'paypal', 'visa', 'mastercard', 'american-express'].map(card => {
      return (
        <li key={`type-${card}`} className={`${styles.cardIcon} u-inline-block u-mrg-r--10`}>
          <img className={`u-fit`} alt={`${card}`} src={`${AppConstants.S3_URL}icons/cards/${card}_white.svg`}/>
        </li>
      )
    })
    return (
      <footer className={`${styles.parent} u-relative u-fit-w t-bg-darkgray u-z-index--3`}>
        <div className={`${styles.row} u-row`}>
          <div className={`${styles.col} u-col-md-2 u-col-lg-2 u-offset-md-1`}>
            <p className='t-title-1 t-color-gray u-pad-y--small'>stella luna</p>
            <ul className='t-color-white'>
              <li className='t-title-1 u-pad-y--small'><Link to='/our-story'>our story</Link></li>
              <li className='t-title-1 u-pad-y--small'><Link to='/stores'>boutiques</Link></li>
              <li className='t-title-1 u-pad-y--small'><Link to='/contact'>contact us</Link></li>
              <li className='t-title-1 u-pad-y--small'><Link to='/size-guide'>Size Guide</Link></li>
            </ul>
          </div>
          <div className={`${styles.col} u-col-md-3 u-col-lg-2`}>
            <p className='t-title-1 t-color-gray u-pad-y--small'>policies</p>
            <ul className='t-color-white'>
              <li className='t-title-1 u-pad-y--small'><Link to='/privacy-statement'>privacy statement</Link></li>
              <li className='t-title-1 u-pad-y--small'><Link to='/terms-and-conditions'>terms&conditions</Link></li>
            </ul>
          </div>
          <div className={`${styles.col} u-col-md-2 u-col-lg-2`}>
            <p className='t-title-1 t-color-gray u-pad-y--small'>follow us</p>
            <ul className='t-color-white'>
              <li className='t-title-1 u-pad-y--small'>
                <Link to='https://www.instagram.com/stellaluna/' target='_blank'>
                  <svg className={`${styles.instagramIcon}`}>
                    <use xlinkHref='#instagram-icon' />
                  </svg>
                  instagram
                </Link>
              </li>
              <li className='t-title-1 u-pad-y--small'>
                <Link to='https://www.facebook.com/StellaLunaFootwear/' target='_blank'>
                  <svg className={`${styles.facebookIcon}`}>
                    <use xlinkHref='#facebook-icon' />
                  </svg>
                  facebook
                </Link>
              </li>
            </ul>
          </div>
          <div className={`${styles.col} u-col-md-3 u-col-lg-3`}>
            <p className='t-title-1 t-color-gray u-pad-y--small'>Payment Methods</p>
            <ul className='t-color-white'>{acceptedCards}</ul>
          </div>
        </div>
        <p className={`${styles.copyright} t-title-1 t-color-gray`}>©Stella Luna - All rights reserved</p>
      </footer>
    )
  }
}

export default Footer
