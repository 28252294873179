import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

const SimpleTextIconButton = (props) => {
  let {
    className,
    negative,
    onClick,
    svgId,
    text
  } = props
  let classNames = [styles.closeButton, className].join(' ')
  const lineHover = text.length === 0 ? '' : styles.lineHover
  return (
    <button className={`${styles.parent} ${lineHover} ${classNames} ${negative ? styles.negative : ''} simple-text-icon-button u-user-select--none`} onClick={onClick}>
      <span className={`${styles.title} t-title-1 u-fit-h u-d-none u-d-lg-inline-block ${negative ? 't-color-white' : 't-color-black'} u-uppercase`}>{text}</span>
      <span className={`${styles.closeIcon} u-inline-block`}>
        <svg className={`u-fit ${negative ? 't-fill-color-white t-stroke-color-white' : '' }`}>
          <use xlinkHref={`#${svgId}`} />
        </svg>
      </span>
    </button>
  )
}

SimpleTextIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  svgId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default SimpleTextIconButton
