import React, { Component } from 'react'
import Counter from 'ccounter'
import transform from 'dom-css-transform'
import Img from 'Components/UI/Img'
import Video from 'Components/UI/Video'
import { productSides } from 'Components/Utils/helpers'
import styles from './index.module.scss'

class BaseSlider extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.wrapper = React.createRef()
    this.FIRST_LOAD = true
    this.LAZY_LOADED = false
    this.RESET_TRANSITION = true
    this.IS_ANIMATING = false
    this.TRANSITION_DURATION = 600
    this.touchStartPos = 0
    this.touchCurrentPos = 0
  }
  componentDidMount() {
    this.counter = new Counter(this.props.slides.length)
    this.resize()
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }
  onMediaDidLoad = () => {
    this.resize()
    if (this.FIRST_LOAD) {
      if (this.props.didLoad) this.props.didLoad()
    }
  }
  load() {
    return new Promise((resolve) => {
      const promises = []
      let totalLoaded = 0
      this.mediaItems.forEach(item => {
        promises.push(item.current.lazyload(() => {
          totalLoaded += 1
          if (totalLoaded === this.mediaItems.length - 1) {
            this.LAZY_LOADED = true
            resolve()
          }
        }))
      })
    })
  }
  slide(dir) {
    if (this.IS_ANIMATING) return
    this.IS_ANIMATING = true
    if (dir === 'prev') this.counter.dec()
    else this.counter.inc()
    if (this.RESET_TRANSITION) {
      this.wrapper.current.style.transitionDuration = '1s'
      this.RESET_TRANSITION = false
    }
    this.translate()
    this.timeout = setTimeout(() => {
      this.IS_ANIMATING = false
    }, this.TRANSITION_DURATION)
  }
  translate() {
    const translateX = -this.itemWidth * this.counter.props.index
    transform(this.wrapper.current, `translate3d(${translateX}px, 0, 0`)
  }
  resize() {
    this.itemWidth = this.parent.current.parentElement.offsetWidth
    this.mediaItems.forEach((item, index) => {
      const parent = item.current.parent.current.parentElement
      const child = item.current
      parent.style.left = `${this.itemWidth * index}px`
      child.resize()
    })
    this.wrapper.current.style.transitionDuration = '0s'
    this.RESET_TRANSITION = true
    if (this.props.slides.length > 1) transform(this.wrapper.current, `translate3d(${-this.itemWidth * this.counter.props.index}px, 0, 0)`)
  }
  onParentClick = () => {
    if (this.props.onClick) this.props.onClick()
    this.resetTouchValues()
  }
  onParentTouchStart = (e) => {
    this.touchStartPos = e.targetTouches[0].pageX
    if (this.touchCurrentPos === 0) this.touchCurrentPos = e.targetTouches[0].pageX
  }
  onParentTouchMove = (e) => {
    this.touchCurrentPos = e.targetTouches[0].pageX
  }
  onParentTouchEnd = () => {
    const remain = this.touchCurrentPos - this.touchStartPos
    if (Math.abs(remain) > 30) {
      const isNext = (remain) < 0
      if (isNext) this.slide('next')
      else this.slide('prev')
      this.resetTouchValues()
    }
  }
  resetTouchValues = () => {
    this.touchStartPos = 0
    this.touchCurrentPos = 0
  }
  render() {
    const { title, color, saison, collection } = this.props.productInfos
    this.mediaItems = []
    const slides = this.props.slides.map((slide, index) => {
      const TagName = slide.type === 'video' ? Video : Img
      const mediaItemRef = React.createRef()
      this.mediaItems.push(mediaItemRef)
      return <div className={`u-absolute u-fit u-overflow-hidden ${this.props.className}`} key={`slider-item-${index}`}>
        <TagName
          {...slide}
          noRetinaOnMobile={true}
          baseUrl={this.props.baseUrl}
          lazy={this.props.lazy}
          pageToGo={this.props.pageToGo}
          autoload={this.props.autoload ? true : index === 0}
          didLoad={this.onMediaDidLoad}
          ref={mediaItemRef}
          alt={`${productSides[index].name} preview product photo of ${title} from ${collection.name} ${saison} collection in ${color.name} color`}
        />
      </div>
    })
    return (
      <div
        onClick={this.onParentClick}
        onTouchStart={this.onParentTouchStart}
        onTouchMove={this.onParentTouchMove}
        onTouchEnd={this.onParentTouchEnd}
        onTouchCancel={this.onParentTouchEnd}
        className={`u-relative u-overflow-hidden u-fit ${this.props.parentWithBorder ? styles.parentWithBorder : ''}`} 
        ref={this.parent}
      >
        <div className={`${styles.wrapper} u-fit`} ref={this.wrapper}>
          { slides }
        </div>
      </div>
    )
  }
}

BaseSlider.defaultProps = {
  baseUrl: '',
  autoload: true,
  parentWithBorder: false,
  productInfos: {
    title: 'Stella Luna product',
    color: {
      name: 'black'
    },
    saison: 'Stella Luna saison',
    collection: {
      name: 'Stella Luna collection'
    }
  }
}

export default BaseSlider
