import React from 'react'
import AppConstants from 'AppConstants'
import styles from './index.module.scss'

export const preparingOrderMessage = () => {
  return (
    <div>
      <img className={`${styles.spinner}`} src={`${AppConstants.S3_URL}misc/spinner_1.gif`} />
      <div className={`${styles.blinkMessage} u-text-center t-bold u-pad-y`}>Please be patient.<br/>Do not refresh.</div>
      <div className={`u-text-center t-bold`}>This may take a few seconds—we are confirming all payment details and preparing your order.</div>
    </div>
  )
}
