import React, { Component } from 'react'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import MainBtn from 'Components/UI/MainBtn'
import { LoginForm, RegisterForm } from 'Components/UI/LoginRegisterForm'
import styles from './index.module.scss'
import { navigate } from 'gatsby'

class PopupLogin extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.checkbox = React.createRef()
    this.state = {
      isLogin: true,
      customer: AppStore.getCustomer(),
      isOpen: false,
      fromCaller: false
    }
  }
  componentDidMount() {
    AppStore.on(AppConstants.OPEN_POPUP_LOGIN_PANEL, this.openPanel)
    AppStore.on(AppConstants.CLOSE_POPUP_LOGIN_PANEL, this.closePanel)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.OPEN_POPUP_LOGIN_PANEL, this.openPanel)
    AppStore.off(AppConstants.CLOSE_POPUP_LOGIN_PANEL, this.closePanel)
    AppStore.off(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.off(AppConstants.REGISTER, this.onRegisterCustomer)
  }
  openPanel = () => {
    AppStore.on(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.on(AppConstants.REGISTER, this.onRegisterCustomer)
    this.setState({ isOpen: true, fromCaller: true })
  }
  closePanel = () => {
    AppStore.off(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.off(AppConstants.REGISTER, this.onRegisterCustomer)
    this.setState({ isOpen: false, fromCaller: false })
  }
  onRegisterCustomer = () => {
    if (AppStore.userIsValid(AppStore.getCustomer())) {
      setTimeout(AppActions.closePopupLoginPanel, 0)
    }
  }
  onLoginCustomer = () => {
    if (AppStore.userIsValid(AppStore.getCustomer())) {
      setTimeout(AppActions.closePopupLoginPanel, 0)
    }
  }
  onLoginSubmit = (user) => {
    setTimeout(AppActions.login, 0, user)
  }
  onRegisterSubmit = (user) => {
    setTimeout(AppActions.register, 0, user)
  }
  sendToRegister = () => {
    this.setState({ isLogin: false })
  }
  sendToLogin = () => {
    this.setState({ isLogin: true })
  }
  render() {
    const openClass = this.state.isOpen ? styles.open : ''
    return (
      <div ref={this.parent} className={`${styles.parent} ${openClass} u-fixed u-fit u-z-index--9981 t-paragraph-0 u-text-left`}>
        <div className={`${styles.insideParent}`}>
          <section className={``}>
            { (this.state.isOpen && this.state.isLogin) &&
              <div>
                <p className={`t-paragraph-1 t-color-black`}>To check out this offering, please log in if you have an account.<br/>If you do not have an account, click below to create an account.</p>
                <div className='u-height--10'/>
                <LoginForm onSubmit={this.onLoginSubmit} onClickAlternativeBtn={this.sendToRegister} />
              </div>
            }
            { (this.state.isOpen && !this.state.isLogin) &&
              <div>
                <p className={`t-paragraph-1 t-color-black`}>Create your account to gain access to this offering.</p>
                <div className='u-height--10'/>
                <RegisterForm onSubmit={this.onRegisterSubmit} onClickAlternativeBtn={this.sendToLogin} />
              </div>
            }
          </section>
          <div className='u-height--only-lg--30' />
          <div className='u-height--lg--20' />
          <MainBtn
            className={`${styles.laterBtn} t-title-1 u-uppercase`}
            color={`black`}
            text={'Maybe later'}
            onClick={() => { window.history.back() }}
          />
          <div className={`u-height--only-lg--30`}></div>
          <div className='u-height--lg--10' />
        </div>
        <div className={`${styles.background}`}></div>
      </div>
    )
  }
}

export default PopupLogin
