import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import './index.module.scss'
import Input from 'Components/UI/Input'
import TextArea from 'Components/UI/TextArea'
import Select from 'Components/UI/Select'

class Form extends Component {
  static defaultProps = {
    className: ''
  }
  state = {
    isValidated: false
  }
  getComponent(item, index) {
    let TagName
    switch (item.type) {
      case 'textarea':
        TagName = TextArea
        break
      case 'select':
        TagName = Select
        break
      default:
        TagName = Input
        break
    }
    return <TagName {...item.props} className={`${item.props.className} u-fit-w`} key={`form-el-${index}`} ref={item.props.id} />
  }
  validate = () => {
    const formLength = this.formEl.length
    if (this.formEl.checkValidity() === false) {
      for (let i = 0; i < formLength; i++) {
        const elem = this.formEl[i]
        const errorLabel = elem.parentNode.querySelector('.invalid-feedback')
        if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
          if (!elem.validity.valid) errorLabel.textContent = elem.validationMessage
          else errorLabel.textContent = ''
        }
      }
      return false
    } else {
        for(let i = 0; i < formLength; i++) {
          const elem = this.formEl[i]
          const errorLabel = elem.parentNode.querySelector('.invalid-feedback')
          if (errorLabel && elem.nodeName.toLowerCase() !== 'button') errorLabel.textContent = ''
        }
      return true
    }
  }
  submitHandler = (event) => {
    event.preventDefault()
    if (this.validate()) {
      this.props.submit()
      this.setState({isValidated: true})
    } else {
      if (this.props.onFail) this.props.onFail()
      this.setState({isValidated: false})
    }
  }
  render() {
    return (
      <form
        ref={form => this.formEl = form}
        onSubmit={this.submitHandler}
        className={`${this.props.className} ${this.state.isValidated ? 'was-validated' : ''}`}
        noValidate
      >
        {
          this.props.items.map((item, index) => {
            let inner
            if (isArray(item)) {
              inner = item.map((subItem, subIndex) => {
                return this.getComponent(subItem, subIndex)
              })
            } else {
              inner = this.getComponent(item, index)
            }
            return (
              <div className={`u-row u-fit-w`} key={`form-row-${index}`}>
              {inner}
              </div>
            )
          })
        }
        {this.props.children}
      </form>
    )
  }
}

Form.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array,
  className: PropTypes.string,
  submit: PropTypes.func.isRequired
}

Form.defaultProps = {
  items: []
}

export default Form
