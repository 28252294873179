import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Link from 'Components/Utils/Link'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import transform from 'dom-css-transform'
import { customerAccountLinks } from 'Components/Utils/helpers'
import styles from './index.module.scss'
import BackgroundBtn from 'Components/UI/BackgroundBtn'

class MenuPanel extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.mainContainer = React.createRef()
    this.state = {
      customer: {}
    }
  }
  componentDidMount() {
    AppStore.on(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.on(AppConstants.LOGOUT, this.onLogoutCustomer)
    this.onLoginCustomer()
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.props.open) this.parentTimeout = setTimeout(() => { if (this.parent.current) transform(this.parent.current, `translateY(${-999999}px)`) }, 400)
    else transform(this.parent.current, `translateY(${0}px)`)
    if (this.props.open && prevProps.open !== this.props.open) this.mainContainer.current.scrollTop = '0px'
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.LOGIN, this.onLoginCustomer)
    AppStore.off(AppConstants.LOGOUT, this.onLogoutCustomer)
  }
  onLoginCustomer = () => {
    const customer = AppStore.getCustomer()
    this.setState({ customer })
  }
  onLogoutCustomer = () => {
    this.setState({ customer: {} })
  }
  render() {
    const customerIsLogged = this.state.customer.id ? true : false
    const mainLink = (key, linkto, name, item) => {
      return (
        <li key={key} className={`${styles.link} t-color-white`}>
          <Link className={`${styles.linkInside} t-paragraph-2`} to={`${linkto}`}>
            <p>
              <span dangerouslySetInnerHTML={{__html: name}}></span>
              { (item && item.showAsNew) && <span className={`t-sub t-bg-red-campaign u-mrg-l--half`}>New</span> }
            </p>
          </Link>
        </li>
      )
    }
    const uiLogged = (
      <div className="u-col-12">
        <div className={`t-paragraph-1 t-color-white u-pad-x`}>My account</div>
        <div className={`u-height--only-lg--20`}></div>
        <div className={`${styles.nameTitle} t-title-1 t-color-gray u-pad-x u-uppercase`}>Hello, {this.state.customer.display_name}</div>
        <ul className={`${styles.panelList}`}>
          {customerAccountLinks.map(item => mainLink(`customer-account-btn${item.to}`, `${item.to}`, item.title))}
        </ul>
        <div className='u-height--40'></div>
        <div className="u-col-12">
          <div className={`u-pad-x`}>
            <BackgroundBtn
              className="u-col-12"
              text="Go to my account"
              onClick={() => { navigate('/my-account/') }}
              negative
              highlighted
            />
            <div className={`u-height--only-lg--20`}></div>
            <BackgroundBtn
              className="u-col-12"
              text="log out"
              onClick={() => { setTimeout(AppActions.logout) }}
              fullBlack
            />
          </div>
        </div>
      </div>
    )
    const uiNotLogged = (
      <div>
        <div className={`t-paragraph-1 t-color-white u-pad-x`}>My account</div>
        <div className={`u-height--only-lg--10`}></div>
        <div className={`u-col-12`}>
          <div className={`t-title-1 t-color-gray u-pad-x`}>Returning Customer?</div>
          <div className={`u-height--only-lg--10`}></div>
          <div className={`u-pad-x`}>
            <BackgroundBtn
              className={`u-col-12`}
              text="Login"
              onClick={() => navigate('/my-account/login/')}
              negative
              highlighted
            />
          </div>
        </div>
        <div className={`u-height--only-lg--20`}></div>
        <div className={`u-col-12`}>
          <div className={`t-title-1 t-color-gray u-pad-x`}>Don't have an account?</div>
          <div className={`u-height--only-lg--10`}></div>
          <div className={`u-pad-x`}>
            <BackgroundBtn
              className={`u-col-12`}
              text="Create account"
              onClick={() => navigate('/my-account/register/')}
              negative
              highlighted
            />
          </div>
        </div>
      </div>
    )
    const collections = [
      ...this.props.collections
    ]
    const latest = this.props.latestItems.map(item => mainLink(`latest-menu-${item.id}`, `${item.to}`, item.name, item))
    const categories = collections.map((collection) => mainLink(`collection-item-${collection.node.slug}`, `/${collection.node.permalink}`, collection.node.name))
    return (
      <div ref={this.parent} className={`${this.props.className} ${styles.panel} ${this.props.open ? styles.isActive : ''} u-fit-w u-fit-vh u-d-lg-none`}>
        <div className={`${styles.mainContainer} u-fixed u-z-index--2`}>
          <div ref={this.mainContainer} className={`${styles.mainContainerInner} u-fixed u-text-left`}>

            <div className={`u-height--only-lg--40`}></div>

            {/* Categories */}
            <div className={`t-title-1 t-color-gray u-pad-x`}>Categories</div>
            <ul className={`${styles.panelList}`}>{categories}</ul>

            <div className={`u-height--only-lg--40`}></div>

            {/* Stories */}
            <div className={`u-col-12`}>
              <div className={`t-title-1 t-color-gray u-pad-x`}>Stories</div>
              <ul className={`${styles.panelList}`}>{latest}</ul>
            </div>

            <div className={`u-height--only-lg--40`}></div>

            {/* My Account */}
            { customerIsLogged && uiLogged }
            { !customerIsLogged && uiNotLogged }

            <div className={`u-height--only-lg--80`}></div>

            { /* Follow us */ }
            <div className={`u-pad-x`}>
              <p className="t-title-1 t-color-gray">follow us</p>
              <ul className="t-color-white">
                <li className="t-title-1 u-pad-y--small">
                  <Link to="https://www.instagram.com/stellaluna/" target="_blank">
                    <svg className={`${styles.instagramIcon}`}>
                      <use xlinkHref="#instagram-icon" />
                    </svg>
                    instagram
                  </Link>
                </li>
                <li className="t-title-1 u-pad-y--small">
                  <Link to="https://www.facebook.com/StellaLunaFootwear/" target="_blank">
                    <svg className={`${styles.facebookIcon}`}>
                      <use xlinkHref="#facebook-icon" />
                    </svg>
                    facebook
                  </Link>
                </li>
              </ul>
            </div>

            <div className={`u-height--only-lg--40`}></div>
          </div>
        </div>
        <div className={`${styles.background} u-fit u-absolute t-bg-black u-z-index--1`} onClick={this.props.onCloseClick}></div>
      </div>
    )
  }
}

export default MenuPanel
