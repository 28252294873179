import React, { Component } from 'react'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import transform from 'dom-css-transform'
import CircledIcon from 'Components/UI/CircledIcon'
import styles from './index.module.scss'

class CookiesPanel extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.state = {
      isOpen: false
    }
  }
  render() {
    const openClass = this.state.isOpen ? styles.open : ''
    return (
      <div ref={this.parent} className={`${styles.parent} ${openClass} u-fixed u-fit-w u-z-index--9983 u-pos-b--0 t-paragraph-0 u-text-center`}>
        <div className={`${styles.insideParent} u-inline-block u-relative`}>
          <p className={`u-text-left t-bold`}>By continuing your navigation, you accept the use of cookies to provide functional services and offers tailored to your interests, in accordance with our <a target={`_blank`} className={`u-simple-hyperlink--negative`} href={`/privacy-statement`}>privacy statement</a> and our <a target={`_blank`} className={`u-simple-hyperlink--negative`} href={`/terms-and-conditions`}>terms and conditions</a>.</p>
          <CircledIcon 
            bgColor={`gray`}
            onClick={() => { setTimeout(AppActions.closeCookiesPanel)}}
            className={`${styles.button} u-absolute`} 
          />
        </div>
      </div>
    )
  }
  componentDidMount() {
    AppStore.on(AppConstants.OPEN_COOKIES_PANEL, this.openPanel)
    AppStore.on(AppConstants.CLOSE_COOKIES_PANEL, this.closePanel)
  }
  componentDidUpdate() {
    if (!this.state.isOpen) this.parentTimeout = setTimeout(() => { if (this.parent.current) transform(this.parent.current, `translateY(${-999999}px)`) }, 800)
    else transform(this.parent.current, `translateY(${0}px)`)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.OPEN_COOKIES_PANEL, this.openPanel)
    AppStore.off(AppConstants.CLOSE_COOKIES_PANEL, this.closePanel)
  }
  openPanel = () => {
    this.setState({isOpen: true})
  }
  closePanel = () => {
    this.setState({isOpen: false})
  }
}

export default CookiesPanel
