import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'
import BaseSlider from 'Components/UI/BaseSlider'
import MainBtn from 'Components/UI/MainBtn'
import AppConstants from 'AppConstants'
import WindowStore from 'WindowStore'
import AppActions from 'AppActions'
import AppStore from 'AppStore'
import Img from 'Components/UI/Img'
import Price from 'Components/Utils/FormatPrice'
import { productSides } from 'Components/Utils/helpers'
import find from 'lodash/find'
require('@gouch/to-title-case')

class ProductItem extends Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.parent = React.createRef()
    this.infos = React.createRef()
    this.colorTitle = React.createRef()
    this.url = props.data.product.permalink
    this.itemWidth = undefined
    this.state = {
      infosAreOpen: this.props.expanded ? true : false,
      sliderLoaded: false,
      isPrivateSale: false
    }
  }
  componentDidUpdate() {
    if (this.state.infosAreOpen) {
      if (this.props.didOpen) this.props.didOpen(this.props.id)
      if (!this.slider.current) return
      if (this.slider.current.LAZY_LOADED) return
      this.slider.current.load().then(() => this.setState({ sliderLoaded: true }))
    }
  }
  componentDidMount() {
    this.setState({ isPrivateSale: this.isPrivateSale })
    this.resize()
  }
  shouldComponentUpdate(nextProps, nextState) {
    const isIdentical = this.checkIfAnythingChangedFromPreviousProps(nextProps, nextState)
    return !isIdentical
  }
  checkIfAnythingChangedFromPreviousProps(nextProps, nextState) {
    let isIdentical = true
    if (nextProps.data.product.id !== this.props.data.product.id) return false
    if (nextState.infosAreOpen !== this.state.infosAreOpen) return false
    if (nextState.sliderLoaded !== this.state.sliderLoaded) return false
    for (var key in nextProps) {
      if (nextProps.hasOwnProperty(key) && typeof nextProps[key] !== 'object') {
        if (nextProps[key] !== this.props[key]) isIdentical = false
      }
    }
    return isIdentical
  }
  setWidth = (width) => {
    this.itemWidth = width
  }
  reset = () => {
    this.setState({ infosAreOpen: false })
  }
  resize = () => {
    if (!this.parent.current) return
    const itemWidth = this.itemWidth ? this.itemWidth : this.parent.current.offsetWidth
    if (this.itemWidth) this.parent.current.style.width = `${itemWidth}px`
    this.parent.current.style.height = `${itemWidth}px`
    if (this.slider.current) this.slider.current.resize()
    this.fullHeight = this.parent.current.offsetHeight + this.infos.current.firstChild.offsetHeight
    this.initHeight = this.parent.current.offsetHeight
    this.infos.current.style.height = this.state.infosAreOpen ? `${this.fullHeight}px` : `${this.initHeight}px`
  }
  onMouseEnter = () => {
    clearTimeout(this.openInfoTimeout)
    this.openInfoTimeout = setTimeout(() => {
      if (WindowStore.getViewType() !== AppConstants.DESKTOP) return
      if (this.props.expanded) return
      this.setState({ infosAreOpen: true })
    }, 300)
  }
  onMouseLeave = () => {
    clearTimeout(this.openInfoTimeout)
    if (WindowStore.getViewType() !== AppConstants.DESKTOP) return
    if (this.props.expanded) return
    this.setState({ infosAreOpen: false })
  }
  onClick = () => {
    if (WindowStore.getViewType() === AppConstants.DESKTOP) return
    if (this.props.expanded) return
    if (!this.state.infosAreOpen) this.setState({ infosAreOpen: true })
  }
  onMediaDidLoad = () => {
    if (this.props.didLoad) this.props.didLoad()
  }
  onFavClick = () => {
    setTimeout(AppActions.addToFavorites, 0, { product: this.props.data.product})
  }
  onRemoveClick = () => {
    if (this.props.removeOnClick) this.props.removeOnClick(this.props.data)
  }
  slide = (dir) => {
    this.slider.current.slide(dir)
  }
  onMainBtnClick = () => {
    if (this.props.buttonOnClick) this.props.buttonOnClick(this.props.data.product)
    else navigate(`/${this.url}`)
  }
  componentWillUnmount() {
    clearTimeout(this.openInfoTimeout)
  }
  onColorMouseEnter = ({color}) => {
    this.colorTitle.current.innerHTML = `Also in ${color.name.toTitleCase()}`
  }
  onColorMouseLeave = () => {
    this.colorTitle.current.innerHTML = ``
  }
  onBaseSliderClick = () => {
    if (WindowStore.getViewType() !== AppConstants.DESKTOP && this.state.infosAreOpen) {
      this.setState({ infosAreOpen: false })
    }
  }
  render() {
    const paragraphSize = this.props.miniVersion ? 't-paragraph-0 t-color-gray' : 't-paragraph-1 t-color-gray'
    const product = this.props.data.product
    const { title, sku, color, taxonomy } = product
    const sale = this.props.data.product.sale ? this.props.data.product.sale : { discount: 0, inSale: false }
    const collection = find(taxonomy, { slug: 'collections' }).subcategories[0]
    const saison = sku.substring(0, 4)
    const price = Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, product.price )
    const productInfos = { title, color, saison, collection }
    return (
      <StaticQuery
        query={graphql`
          query {
            dataJson {
              PRIVATE_SALE
            }
          }
        `}
        render={data => {
          this.isPrivateSale = data.dataJson.PRIVATE_SALE
          const isPrivateSale = this.state.isPrivateSale
          const lockSale = this.props.lockSale && isPrivateSale
          const showDiscount = sale.inSale && !this.state.infosAreOpen && !this.props.miniVersion && !lockSale
          return (
            <div
              className={`${styles.parent} ${this.props.className ? this.props.className : ''} ${this.state.sliderLoaded ? styles.isLazyloaded : ''} ${this.state.infosAreOpen ? styles.isActive : ''} u-relative t-bg-white u-fit`}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              onClick={this.onClick}
              ref={this.parent}
            >
              { showDiscount &&
                <div className={`${styles.discount} t-paragraph-1 t-color-red u-uppercase`}>-{sale.discount}%</div>
              }
              { !this.state.infosAreOpen && <div className='u-absolute u-pos-tl u-fit u-z-index--1 u-d-lg-none'/> }
              { this.props.miniVersion &&
                <div className={`u-btn`} onClick={() => { if (this.props.pageToGo && AppStore.route.new.path !== this.props.pageToGo) navigate(this.props.pageToGo) }}>
                  <Img
                    lazy={this.props.lazy}
                    className={this.props.extraClasses}
                    baseUrl={this.props.baseUrl}
                    didLoad={() => {
                      this.resize()
                      this.onMediaDidLoad()
                    }}
                    src={product.media.rect[0].src}
                    srcRetina={product.media.rect[0].srcRetina}
                    noRetinaOnMobile={true}
                    alt={`${productSides[0].name} preview product photo of ${title} from ${collection.name} ${saison} collection in ${color.name} color`}
                  />
                </div>
              }
              {/* Slider with arrows */}
              {!this.props.miniVersion &&
                <div className={`${styles.slider} u-relative u-fit`}>
                  <BaseSlider
                    lazy={this.props.lazy}
                    ref={this.slider}
                    onClick={this.onBaseSliderClick}
                    autoload={false}
                    baseUrl={this.props.baseUrl}
                    parentWithBorder={true}
                    pageToGo={(WindowStore.getViewType() !== AppConstants.DESKTOP && this.state.infosAreOpen) ? undefined : this.props.pageToGo}
                    slides={product.media.rect}
                    didLoad={this.onMediaDidLoad}
                    className={`${this.props.extraClasses}`}
                    productInfos={productInfos}
                  />
                  <div className={`${styles.buttonsContainer} ${styles.extras} u-absolute u-pos-y-center u-fit-w`}>
                    <button className={`${styles.button} ${styles.buttonLeft} u-absolute u-pos-l--0 u-text-center`} onClick={this.slide.bind(null, 'prev')} onTouchStart={() => { console.log('prev') }}>
                      <span className='u-inline-block u-fit'>
                        <svg className='u-fit'>
                          <use xlinkHref='#arrow-icon' />
                        </svg>
                      </span>
                    </button>
                    <button className={`${styles.button} ${styles.buttonRight} u-absolute u-pos-r--0 u-text-center`} onClick={this.slide.bind(null, 'next')} onTouchStart={() => { console.log('next') }}>
                      <span className='u-inline-block u-fit'>
                        <svg className='u-fit'>
                          <use xlinkHref='#arrow-icon' />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              }
              { (product.other_colors.length > 0 && this.props.showOtherColors) &&
                <div className={`${styles.extras} u-absolute u-inline-block u-pos-t--0 u-pos-l--0 u-pad`}>
                  {
                    product.other_colors.filter(item => item.availability_status !== 'hidden').map((color, i) => <Link onMouseLeave={this.onColorMouseLeave} onMouseEnter={this.onColorMouseEnter.bind(null, color)} to={`/${color.permalink}`} className={`${styles.colorVariant} u-inline-block`} style={{ backgroundColor: color.color.code }} key={`color-variant-${color.color.code}-${i}`}></Link>)
                  }
                  <div ref={this.colorTitle} className={`${styles.colorInfo} u-inline-block t-paragraph-0 t-color-gray`}></div>
                </div>
              }
              {/* Heart Infos */}
              {this.props.showFavoriteIcon && <button className={`${styles.heartIcon} ${styles.extras} u-absolute u-inline-block u-pos-t--0 u-pos-r--0 u-pad`} onClick={this.onFavClick}>
                <svg className='u-fit'>
                  <use xlinkHref='#heart-icon' />
                </svg>
              </button> }
              {this.props.showRemoveIcon && <button className={`${styles.removeIcon} ${styles.extras} u-absolute u-inline-block u-pos-t--0 u-pos-r--0 u-pad--small`} onClick={this.onRemoveClick}>
                <span className='u-relative u-block u-fit'></span>
              </button> }
              {/* Infos */}
              <div style={{ height: this.state.infosAreOpen ? `${this.fullHeight}px` : `${this.initHeight}px` }} className={`${styles.infos} u-absolute u-pos-t--0 u-pos-l--0 t-bg-white u-text-center`} ref={this.infos}>
                <div  className={`u-absolute u-pos-b--0 u-pos-l--0 u-fit-w u-pad-x--small`}>
                  <Link to={`/${this.url}`} className={`${styles.infosItem} t-title-1 u-uppercase t-color-black`}>{product.title}</Link>
                  <Link to={`/${this.url}`}>
                    <p className={`${styles.price} ${styles.infosItem} ${paragraphSize}`}>
                      { (!sale.inSale || lockSale) && <span>{price}</span> }
                      { (sale.inSale && !lockSale) &&
                      <span>
                        <span className={styles.percentSale}>-{sale.discount}%</span>
                        <del>
                          <span>{price}</span>
                        </del>
                        <span className={styles.salePrice}>{Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, sale.price )}</span>
                      </span>
                      }
                    </p>
                  </Link>
                  { this.props.showInfo && <div className={`${styles.infosItem} ${paragraphSize}`}>Color: {product.color.name.toTitleCase()}</div> }
                  { (this.props.showInfo && this.props.data.size) && <p className={`${styles.infosItem} ${paragraphSize}`}>Size: {this.props.data.size.name}</p> }
                  <div className='u-height--10' />
                  <MainBtn url={`/${this.url}`} onClick={this.onMainBtnClick} className={styles.infosItem} text={this.props.buttonText} />
                  <div className='u-height--20' />
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

ProductItem.defaultProps = {
  baseUrl: '',
  showFavoriteIcon: true,
  showRemoveIcon: false,
  showInfo: false,
  lockSale: true,
  showOtherColors: true,
  buttonText: 'shop now',
  expanded: false,
  miniVersion: false
}

export default ProductItem
