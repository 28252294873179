import React from 'react'
import Helmet from 'react-helmet'
import Config from 'Data/config'

const SeoHead = ({
  title = Config.siteTitle,
  description = Config.siteDescription,
  path = '',
  keywords = Config.siteKeywords,
  ogImage = Config.siteOgImage,
  twitterImage = Config.siteTwitterImage
}) => {
  const siteTitle = `Stella Luna — ${title}`
  return (
    <Helmet>
      {/* General */}
      <meta charset="utf-8"/>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>{siteTitle}</title>
      <link rel="canonical" href={`${Config.siteUrl}${path}`}/>
      <meta name="description" content={description}/>
      <meta name="keywords" content={keywords}/>
      <meta name="author" content={Config.siteAuthor}/>
      <meta name="copyright" content={Config.siteCopyright}/>
      <meta name="location" content={Config.siteLocation}/>

      {/* OG meta */}
      <meta property="og:title" content={siteTitle}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={ogImage}/>
      <meta property="og:url" content={`${Config.siteUrl}/${path}`}/>
      <meta property="og:site_name" content={siteTitle}/>
      <meta property="fb:app_id" content={Config.siteFBAppID && Config.siteFBAppID !== '' ? Config.siteFBAppID : ''} />
      <meta property="og:type" content={'website'} />

      {/* Twitter meta */}
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:site" content={Config.siteTwitter}/>
      <meta name="twitter:title" content={siteTitle}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image:src" content={twitterImage}/>

      {/* Favicons */}
      <link rel="apple-touch-icon" sizes="180x180" href={`${Config.S3_URL}misc/favicon/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${Config.S3_URL}misc/favicon/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${Config.S3_URL}misc/favicon/favicon-16x16.png`} />
      <link rel="manifest" href={`${Config.S3_URL}misc/favicon/site.webmanifest`} />
      <link rel="mask-icon" href={`${Config.S3_URL}misc/favicon/safari-pinned-tab.svg" color="#656565`} />
      <link rel="shortcut icon" href={`${Config.S3_URL}misc/favicon/favicon.ico`} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content={`${Config.S3_URL}misc/favicon/browserconfig.xml`} />
      <meta name="theme-color" content="#ffffff" />

    </Helmet>
  )
}

export default SeoHead
