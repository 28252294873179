import React, { Component } from 'react'
import AppActions from 'AppActions'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import Input from 'Components/UI/Input'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import styles from './index.module.scss'
import Form from 'Components/UI/Form'

class Newsletter extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.defaultText = 'Enter your mail'
  }
  componentDidMount() {
    AppStore.on(AppConstants.SUBSCRIBE_TO_NEWSLETTER, this.onSubscribeToNewsletter)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.SUBSCRIBE_TO_NEWSLETTER, this.onSubscribeToNewsletter)
  }
  onSubscribeToNewsletter = () => {
    this.input.current.setValueExplicit('')
  }
  onSubmit = () => {
    setTimeout(AppActions.subscribeToNewsletter, 0, { email: this.input.current.state.value, silenced: false })
  }
  render() {
    const form = [
      {
        type: 'input',
        props: {
          type: 'email',
          id: 'subscribeNewsletterEmail',
          className: 'u-remove-autofill u-col-12 u-col-md-7',
          placeholder: this.defaultText,
          required: true
        }
      }
    ]
    return (
      <div className={`${styles.parent} u-relative u-fit-w u-text-center t-bg-white u-pad-y--spacing u-z-index--3`}>
          <div className={`${styles.innerParent}`}>
            <p className={`t-big-title`}><span className={`u-inline-block u-pad-x--md`}>join our newsletter</span></p>
            <div className={`u-height--10`}></div>
            <div className={`${styles.wrapper}`}>
              <Form items={[]} submit={this.onSubmit} className={`u-row`}>
                <Input ref={this.input} {...form[0].props} className={`${form[0].props.className} ${styles.mainInput} u-text-left`} />
                <div className={`${styles.mainBtn} u-col-12 u-col-md-4 u-offset-md-1`}>
                  <BackgroundBtn
                    className={`u-col-12`}
                    text='subscribe'
                    type='submit'
                    highlighted
                    negative
                  />
                </div>
              </Form>
            </div>
          </div>
      </div>
    )
  }
}

export default Newsletter
