import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import AppConstants from 'AppConstants'

class Transition extends Component {
  state = {
    exiting: false
  }
  constructor(props) {
    super(props)
    this.listenerHandler = this.listenerHandler.bind(this)
  }
  listenerHandler(event) {
    this.setState({ exiting: true })
  }
  componentDidMount() {
    window.addEventListener(AppConstants.HISTORY_EXITING_EVENT_TYPE, this.listenerHandler)
  }
  componentWillUnmount() {
    window.removeEventListener(AppConstants.HISTORY_EXITING_EVENT_TYPE, this.listenerHandler)
  }
  render() {
    return (
      <CSSTransition
        timeout={600}
        in={true}
        appear={true}
        enter={false}
        exit={false}
        classNames={{
          appear: 'u-fade-appear',
          appearActive: 'u-fade-appear-active--pages'
        }}>
        <div>{this.props.children}</div>
      </CSSTransition>
    )
  }
}

export default Transition
