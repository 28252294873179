import React, { Component } from 'react'
import AppStore from 'AppStore'
import { CSSTransition } from 'react-transition-group'
import AppConstants from 'AppConstants'
import transform from 'dom-css-transform'
import styles from './index.module.scss'

class LockedScreenMessagesPanel extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.state = {
      dom: undefined,
      color: `dark`,
      isOpen: false
    }
  }
  render() {
    const openClass = this.state.isOpen ? styles.open : ''
    const colorTheme = this.state.color === `light` ? styles.lightTheme : ``
    return (
      <div ref={this.parent} className={`${styles.parent} ${openClass} ${colorTheme} u-fixed u-fit u-z-index--9984 t-paragraph-0 u-text-center`}>
        <CSSTransition
          in={this.state.isOpen}
          unmountOnExit
          timeout={400}
          classNames={{
            enter: styles.fullscreenSlideshowEnter,
            enterActive: styles.fullscreenSlideshowEnterActive,
            enterDone: styles.fullscreenSlideshowEnterDone,
            exit: styles.fullscreenSlideshowExit,
            exitActive: styles.fullscreenSlideshowExitActive,
            exitDone: styles.fullscreenSlideshowExitDone,
          }}>
          <div>
            <div className={`${styles.insideParent}`}>{ this.state.dom }</div>
            <div className={`${styles.background}`}></div>
          </div>
        </CSSTransition>
      </div>
    )
  }
  componentDidMount() {
    AppStore.on(AppConstants.OPEN_LOCKED_MESSAGES_PANEL, this.openPanel)
    AppStore.on(AppConstants.CLOSE_LOCKED_MESSAGES_PANEL, this.closePanel)
  }
  componentDidUpdate() {
    if (!this.state.isOpen) this.parentTimeout = setTimeout(() => { if (this.parent.current) transform(this.parent.current, `translateY(${-999999}px)`) }, 400)
    else setTimeout(() => { transform(this.parent.current, `translateY(0px)`) }, 0)
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.OPEN_LOCKED_MESSAGES_PANEL, this.openPanel)
    AppStore.off(AppConstants.CLOSE_LOCKED_MESSAGES_PANEL, this.closePanel)
  }
  openPanel = (item) => {
    // color theme `dark` or `light`
    const { dom, color } = item
    this.setState({ isOpen: true, dom, color })
  }
  closePanel = () => {
    this.setState({ isOpen: false })
  }
}

export default LockedScreenMessagesPanel
