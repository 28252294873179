import React, { PureComponent } from 'react'
import styles from './index.module.scss'

class Checkbox extends PureComponent {
  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.state = {
      checked: this.props.checked
    }
  }
  onChange = () => {
    this.setState({ checked: !this.state.checked }, this.props.onChange)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked })
    }
  }
  render() {
    return (
      <div onClick={this.onChange} className={`${this.props.className} u-relative u-user-select--none`}>
        <div
          className={`${styles.input} ${this.state.checked ? styles.checked : ''} t-color-black u-absolute u-z-index--back t-paragraph-1 u-inline u-block form-control`}
          id={`${this.props.id}`}
          ref={this.input}
        />
        <label className={`${styles.label} u-relative u-user-select--none`} htmlFor={this.props.id}>{this.props.label}</label>
        <div className={`${styles.feedback} invalid-feedback t-paragraph-0`}></div>
      </div>
    )
  }
}

Checkbox.defaultProps = {
  className: '',
  value: '',
  checked: false,
  required: false
}

export default Checkbox
